import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePage } from '../../PageProvider';
import Header from './Header';
import NavBar from './NavBar';

function Layout() {
  const page = usePage();

  return (
    <>
      <Header>
        <h1 className="text-center text-2xl font-medium text-black">
          {page.getTitle?.() ?? ''}
        </h1>
      </Header>
      <main>
        <Outlet />
      </main>
      <NavBar />
    </>
  );
}

export default Layout;
