const translation = {
  'You switched to {{ language }}': 'Vous êtes passé à {{ language }}',
  'French': 'Français',
  'Home': 'Accueil',
  'Search': 'Recherche',
  'Saved': 'Sauvegardé',
  'Me': 'Moi',
  'Menu': 'Menu',
  'English': 'Anglais',
  'Settings': 'Paramètres',
  'Language': 'Langues',
  'Saved Items': 'Eléments sauvegardés',
  'My tasks': 'Mes tâches',
  'Parts': 'Pièces détachées',
  'Saved items': 'Eléments sauvegardés',
  'Notifications': 'Notifications',
  'Help & Feedback': 'Aide & Retours utilisateur',
  'Logout': 'Déconnexion',
  'Download for offline': 'Télécharger pour une utilisation ultérieure',
  'Repair Action': 'Activité de Réparation',
  'Repair action': 'Activité de Réparation',
  'Part': 'Pièce détachée',
  'Refreshed {{time}} min ago': 'Rafraichissement il y a {{time}}',
  'Recently viewed': 'Consulté récemment',
  'View all': 'Voir tous',
  'Clear All': 'Annuler la sélection',
  'Expand all': 'Déployer toute la sélection',
  'Collapse all': 'Réduire toute la sélection',
  'Search results': 'Résultat de la recherche',
  'Repairs': 'Réparations',
  'Downloading content...': 'Télécharger le contenu...',
  'Bookmarks': 'Signets',
  'Downloads': 'Téléchargements',
  'Recent': 'Récents',
  'Recent codes': 'Codes récents',
  'Saved codes': 'Codes sauvegardés',
  'Updates': 'Mise à jour',
  'Clear': 'Annulation',
  'Updated Content for {{item}}.': 'Contenu mis à jour pour {{item}}',
  '{{item}} has been saved': '{{item}} a été sauvegardé',
  '{{item}} could not be saved': "{{item}} n'a pas pu être sauvegardé",
  '{{item}} has been removed from saved':
    '{{item}} a été supprimé de la liste de sauvegarde',
  '{{item}} could not be removed from saved':
    "{{item}} n'a pu être supprimer de la liste de sauvegarde",
  'Difficulty': 'Difficulté',
  'Repair time': 'Temps de réparation',
  'min_one': '1 min',
  'min_other': '{{count}} min',
  'min_zero': '0 min',
  'Rating': 'Classement',
  'Thank you for the feedback': 'Merci de votre retour',
  'Not relevant': 'Non pertinent',
  'Relevant': 'Pertinant',
  'Fixed my issue': 'Cela a résolu le problème',
  'Leave feedback': 'Laissez un retour',
  'Save item': "Sauvegarder l'objet",
  'Low': 'Bas',
  'Medium': 'Médium',
  'High': 'Haut',
  'This was relevant info': 'Ceci était une information pertinente',
  'This was not relevant info': "Ceci n'était pas une information pertinente",
  'Add to saved items': 'Ajouter à la liste de sauvegarde',
  'Remove from downloads': 'Enlever de la liste de sauvegarde',
  'Search AllConnect Field Support': 'Rechercher AllConnect Field Support',
  'Filters': 'Filtres',
  'Page not found': 'Page inconnue',
  'Return home': "Retour à l'accueil",
  'Clear all bookmarks': 'Effacer tous les signets',
  'Search all EFC codes': 'Rechercher tous les codes EFC',
  'Search all parts': 'Rechercher toutes les pièces de rechange',
  'Results ({{resultsCount}})': 'Résultats ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} a déjà été sauvegardé',
  'Details': 'Détails',
  'Equipment': 'Equipement',
  'Notes': 'Notes',
  'Equipment Information': "Informations sur l'équipement",
  'Equipment ID': 'ID équipement',
  'Model': 'Modèle',
  'Terminal ID': 'ID du terminal ',
  'Device serial number': "Numéro de série de l'appareil",
  'Add to equipment list': "Ajouter à la liste d'équipement",
  'EFC codes': 'EFC codes',
  'Steps': 'Etapes',
  'Module content': 'Contenu du module',
  'Repair action content': 'contenu des activités de réparation',
  'Fixed issue': 'Problème réparé',
  'Part Number {{partId}} copied to the clipboard':
    'Code article sauvé dans le presse-papier {{partId}}',
  'Relationship tree': 'Arbre des relations',
  'Potential substitutions': 'Substitutions possibles',
  'Loading': 'Chargement ...',
  'Removed from saved searches': 'Retiré des recherches sauvegardées',
  'Added to saved searches': 'Ajouté aux recherches sauvegardées',
  'All bookmarks cleared': 'Tous les signets effacés',
  'No bookmarks to clear': 'Aucun signet à effacer',
  'All recent items cleared': 'Tous les éléments récents effacés',
  'No recent items to clear': 'Aucun élément récent à effacer',
  'You have no recently view history.':
    "Vous n’avez pas d'affichage d’historique récent.",
  'Please wait while data is being loaded in {{language}}':
    'Veuillez patienter pendant le chargement des données en {{language}}',
  'Signing you in': 'Veuillez vous connecter',
  'Signing you out': 'Veuillez vous déconnecter',
  'Oops': 'Oups',
  'Switching language to {{ language }}': 'Passage à la langue {{ language }}',
  'Data has been fetched in': 'Les données ont été récupérées dans',
  'Failed to fetch data in': 'Échec de la récupération des données dans',
  'No results': 'Aucun résultat',
  'AllConnect Field Support': 'Support pour AllConnect',
  'Preventive Insights': 'Tendances pour la Maintenance Préventive',
  'Service Insights': 'Tendances pour les Services',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    "Le changement de langue s'effctue en 2 phases. Les données statiques telles que les étiquettes et les titres sont changés immmédiatement. Les données dynamiques nécessitent une connexion et la mise à jour peut prendre d'une à deux minutes. La langue n'est changée que lorsque l'ensemble du contenu est téléchargé;",
  'URL': 'Adressse Web',
  'User Name': "Nom d'utilisateur",
  'ACFS App': 'Application ACFS',
  'ACFS UI': "Interface Graphique d'ACFS",
  'Offline Data': 'Données téléchargée',
  'Info': 'Info',
  'Repair': 'Réparation',
  'RepairActionCount 1': '1 action de réparation',
  'RepairActionCount {{count}}': '{{count}} actions de réparations',
  'RepairActionCount 0': 'Auncune action de réparation',
  'All': 'Tout',
  'Sync': 'Synchronisation',
  'Last 30 days': 'Derniers 30 jours',
  'Not yet implemented': 'Pas encore implémenté',
  'Categories': 'Catégories',
  'Repair actions': 'Acitons de réparation',
  'Module': 'Modules',
  'Preferences': 'Préférences',
  'Service Manuals': "Manuels d'Entretien",
  'Service Bulletins': 'Notes de Service',
  'FCOs': 'FCOs',
  'FAQs': 'Questions-Réponses',
  'Training Content': 'Contenu de Fomrmation',
  'Your feedback has been sent': 'Votre remarque a été envoyée',
  'Failed to send your feedback': "Votre remarque n'a pu être envoyée",
  'Comments are required': 'Vos commentaires sont nécessaires',
  'Comments should not be more than 100 characters':
    'Vos commentaires ne doivent pas dépasser 100 caractères',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Un maximum de {{numOPhotos}} photos peuvent être chargées',
  'Add this Task - {{taskNumber}}': 'Ajouter cette tâche - {{taskNumber}}',
  "Other '{{inputValue}}'": 'Autre {{inputValue}}',
  'Other (Type to add) / Blank': 'Autre (cliquer pour ajouter) / Vide',
  'General feedback': 'Commentaire général',
  'Feedback: EFC code - {{efcCode}}': 'Commentaire : code EFC - {{efcCode}}',
  'Module: {{moduleName}}': 'Module : {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    "Commentaire pour un code EFC spécifique, une action ou une pièce de réparation doit être soumis en utilisant l'option de commentaire disponible à ce niveau",
  'Corrective action required': 'Action corrective nécessaire',
  'Yes': 'Oui',
  'No': 'Non',
  'Task number': 'Numéro de tâche',
  'Select or Type to add': 'Séléctionner ou cliquez pour ajouter',
  'Comments': 'Commentaire',
  'Delete': 'Suppression',
  'Open Camera': "Ouvrir l'appareil photo",
  'Upto {{maxFiles}} images can be selected':
    'Un maximum de {{maxFiles}} images peuvent être sélectionneés',
  'Cancel': 'Annuler',
  'Add': 'Ajouter',
  'Add equipment': 'Ajouter un appareil',
  'Serial number': 'Numéro de Série',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    "Scannez le code de l'appareil ou saisissez le numéro de série pour obtenir les détails de cet appareil ",
  'Last synced: {{time}} min ago':
    'Dernière synchronisation il y a {{time}} minutes',
  'Send': 'Envoyé',
  "Task Number must be provided if 'Corrective action required' is Yes":
    "Le numéro de la tâche doit être fourni si 'Une action corrective est nécessaire' est à Oui",
  'Time to fix (minutes)': 'Temps de réparation (minutes)',
  'Time to fix should not be more than 999':
    'Le temps de réparation ne doit pas être supérieur à 999',
  'Please enter positive numbers only': 'Merci de saisir des nombres positifs',
  'Please enter numbers only': 'Merci de ne saiis que des chiffres',
  'Clear filters': 'Effacer les filtres',
  'Show results': 'Résultats',
  'Your feedback has been submitted': 'Votre commentaire a été soumis',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'Cet EFC est relié à un lien d\'enrichissement qui est actuellement actif.Merci de nous indiquer si cet enrichissemeent est pertinent (l\'action à faire est pertinente).Si vous souhaitez laisser un commentaire additionel, sélectionnez "Soumettre un commentaire"',
  'Products': 'Produits',
  'Product Number {{productId}} copied to the clipboard':
    "L'identifiant du produit {{productId}} a été copié dans le presse-papier",
  'View Groups': 'Visualiser les groupes',
  'Search all products': 'Chercher parmis tous les produits',
  'Current': 'Actuelle',
  'History': 'Historique',
  'No equipment data found for serial number {{number}}.':
    "Aucune donnée n'a été trouvée pour ce numéro de série {{number}}.",
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Impossible de récupérer les données de configuration pour ce numéro de série {{number}}.',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Impossible de récupérer les données de cet équipement. Utilisation des données locales',
  'Unable to retrieve service task. Falling back to offline data.':
    'Impossible de récupérer les tâches de service. Utilisation des données locales',
  'Errors': 'Erreurs',
  'Tasks': 'Tâches',
  'History Parts': 'Historique des pièces détachées',
  'Unable to retrieve Service Task history.':
    "Impossible de récupérer l'historique des opérations de service",
  'Recommended Parts': 'Pièces détachées recommandées',
  'Service Task Details': 'Détails des tâches de maintenance',
  'Task Number': 'Numéro de la tâche',
  'Task Status': 'Etat de la tâche',
  'Problem': 'Problème',
  'Task Type': 'Type de tâche',
  'Customer': 'Client',
  'Effective Date': 'Date actuelle',
  'Device Description': "Description de l'équipement",
  'Device Serial Number': "Numéro de série de l'équipement",
  'EndPoint Id': 'Identifiant du EndPoint',
  'Service Task Notes {{length}}.':
    'Notes pour la tache de maintenance {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    "La référence de pièce {{partId}} n'a pas été trouvée sur DNSpares",
  'Number': 'Nombre',
  'Site Number': 'Numéro de site',
  'Address': 'Adresse',
  'Contact Date': 'Date de prise de contact',
  'Contact Name': 'Nom du contact',
  'Contact Email': 'Adresse email du contact',
  'Contact Phone': 'Téléphone du contact',
  'Contract Hours': 'Plage horaire du contrat',
  'Site Messages:': 'Message concernant le site:',
  'Type': 'Type',
  'Product': 'Produit',
  'Desc': 'Description',
  'Task Number should not be more than 30 characters':
    "L'identifiant de tâche ne doit pas dépasser 30 caractéres",
  'Select Photos': 'Seléctionnez les photos',
  'Session Expired': 'LA session a expirée',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    "La session a expirée pendant que votre appareil était hors connexion. Veuillez rétablir la connexion et cliquer sur le bouton login ci-dessous pour exécuter la séquence de connexion et arriver à l'accueil",
  'Login': 'Connexion',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Merci de faire parvenir vos commentaires pour améliorer le contenu du site ACFS sans rapport à un EFC ou une action de réparation spécifique',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Merci de faire parvenir vos commentaires pour améliorer le contenu de ce code EFC',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    "Est-ce qu'il manque quelque chose à cette Action de réparation ? Par ex. devrait-on ajuster la pertinence ou la durée de réparation ? Merci de nous transmettre votre commentaire pour améliorer le contenu spécifique de cette action de réparation et son lien avec l'EFC qui l'a déclenché\"",
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Sélectionnez "Oui" si vous recommandez un changement à effectuer par les administrateurs',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    "Envoyer un commentaire n'est pas obligatoire. Merci d'utiliser l'anglais sipossible. Vos commetnaires sont utilisés pour améliorer le contenu de ACFS. Les commentaires peuvent être partagés avec la hiérarchie pour en assurer la qualité. Les administrateurs peuvent vous contacter pour demander des informations complémentaires afin d'améliorer le contenu ou pour tout autre information professionnelle",
  'OfflineMessage':
    'Lorsque ACFS est hors connexion, certains contenus ne sont pas disponible, du fait de leur nature, de la fréquence des téléchargements, ou des capacités de stockage de votre appareil. Du fait de ...',
  'OfflineMessage1':
    "Sur la page d'Accueil et Votre page, la section Mes tâches ne montre que les tâches ouvertes sur votre appareil ",
  'OfflineMessage2':
    "Sur les pages liées aux equipéments (Equipements, Détails, Pièces détachées), les données de l'équipement et de maintenance seront disponibles sur votre appareil",
  'OfflineMessage3':
    "La pages des Notes ne montre que les tâches ouvertes de l'utilisateurs telles que disponibles sur l'appareil correspondant à l'équipement",
  'OfflineMessage4':
    "L'ajout de la fonctionnalité Equipement sur Votre page et la page Equipement ne fonctionnera pas puisque les informations de cet équipement ne peuvent être collectées ",
  'Feedback: Repair # {{repairActionId}}':
    'Commentaire : Réparation # {{repairActionId}}',
  'Training': 'Formation',
  'General Help': 'Aide générale',
  'Offline Disclaimer': 'Clause de Non Responsabilité hors-ligne',
  'Nothing here yet': "Pas d'élément disponible encore",
  'Unable to retrieve tasks. Falling back to offline data.':
    'Impossible de récupérer la tâche. Utilisation des données locales.',
  'Category': 'Catégorie',
  'Family': 'Famille',
  'Skill level required': 'Niveau de compétence requis',
  'Sort by': 'Trier par',
  'Subsystem': 'Sous-système',
  'Recent records': 'Enregistrements récents',
  'Systems': 'Système',
  'Task - {{taskNumber}}': 'Tâche - {{taskNumber}}',
  'Add New': 'Ajouter',
  'Everywhere': 'Partout',
  'Keywords': 'Mots-clés',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    "La page des détails ne contient des données que si l'utilisateur de cet équipement y a été assigné",
  'OfflineMessage5':
    'Les images qui concernent le contenu de l’application comme les images EFC, les images des étapes d’action de réparation, les images des pièces ne s’afficheront pas si l’utilisateur est hors ligne',
  'Module(EFC only)': 'Module (EFC uniquement)',
  'German': 'Allemand',
  'Polish': 'Polonais',
  'Espanol': 'Espagnol',
  'Indonesian': 'Indonésien',
  'Chinese': 'Chinois',
  'Thai': 'Thaï',
  'Vietnamese': 'Vietnamien',
  'Portuguese': 'Portugais',
  'Turkish': 'Turc',
  'If no stock, replaces': 'S’il n’y a pas de stock, remplace',
  'If no stock, replaced by': 'S’il n’y a pas de stock, remplacé par',
  'Replaces': 'Remplace',
  'Replaced by': 'Remplacé par',
  'Repair Id': 'ID de réparation',
  'View Child Ids': 'Afficher les ID enfants',
  'Device Id': 'ID de l’appareil',
  'Equipment with serial number {{number}} added Succesfully.':
    'Équipement avec le numéro de série {{number}} ajouté avec succès.',
  'mfg': 'Numéro de série',
  'Reporting System': 'Rapports d’activités',
  'Update': 'mise à jour',
  'No Endpoint': 'No Endpoint',
  'Repair Action performed':
    'Avez-vous réalisé la réparation proposée par ACFS',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Mise à jour : Les noms d’utilisateurs sont désormais visibles dans l’historique des tâches. Vous verrez les noms des autres utilisateurs, et les autres verront le vôtre. Cette modification permet d’identifier plus facilement les utilisateurs précédents pour les contacter en cas de besoin d’informations supplémentaires.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'Le contenu de la base de connaissance d’ACDE est en cours de téléchargement. Veuillez nous excuser pour le temps d’attente. Cette page sera automatiquement redirigée une fois le contenu téléchargé.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Veuillez nous excuser pour la gêne occasionnée. Le Lien ne pointe pas vers une page valide dans ACFS.',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'Le point de connexion de la page Action de Réparation ne correspond pas au point de connexion de la tâche active assignée. Cliquez "OK" pour continuer, "Annuler" pour modifier.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Seléctionnez "Oui" si vous recommendez qu’une nouvelle action de réparation soit créée par les administrateurs de l’application.',
  'Select this repair action template to specify the action completed':
    'Seléctionnez ce modèle d’Action de Réparation pour indiquer que l’action est finalisée.',
  'Custom Repair Performed': 'Réparation spécifique réalisée.',
  'Submit': 'Soummettre',
  'Needs reviewed for new RA':
    'Besoins révisés pour une nouvelle Action de Réparation',
  'Title': 'Titre',
  'Title of repair': 'Titre de la Réparation',
  'Description of repair': 'Description de la Réparation',
  'Duration to complete repair (minutes)': 'Durée de la Réparation complète',
  '3 or more Characters are required for the search.':
    '3 caractères ou plus sont nécessaires pour la recherche.',
  'Title is required': 'Title is required',
  'Title should not be more than 200 characters':
    'Le titre ne doit pas dépasser 200 caractères',
  'Details is required': 'Des détails sont nécessaires',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    "Le changement de langue se fait en 2 phases. Les données statiques, telles que les étiquettes et les titres, sont modifiées immédiatement. Les données relatives au contenu nécessitent une connexion en ligne et peuvent prendre jusqu'à une minute ou plus, selon votre connexion, pour actualiser le choix de la nouvelle langue. Tant que le contenu n'est pas téléchargé, l'application continue d'afficher la langue précédente.  Les éléments récemment consultés et sauvegardés seront les derniers à être mis à jour et peuvent prendre jusqu'à une minute supplémentaire.",
  'No results Or Multiple Results, please narrow your search':
    'Aucun résultat ou plusieurs résultats, veuillez affiner votre recherche',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Saisissez le numéro de série pour afficher les aperçus et les détails de cet équipement.',
  'Open': 'Ouvrir',
  'Interrupt': 'Interruption',
  'Incomplete': 'Incomplet',
  'Closed': 'Fermé',
  'Cancelled': 'Annulé',
  'Scanned successfully, Redirecting to the scanned link':
    'Scan réussi, redirection vers le lien scanné',
  'Search Serial number': 'Recherche Numéro de série',
  'No System is Selected': "Aucun système n'est sélectionné",
  'Cancelled Tasks': 'Tâches annulées',
  'Allowed': 'Autorisé',
  'Not Allowed': 'Non autorisé',
  'Open Task UserId': 'Tâche ouverte UserId',
  'Observations': 'Observations',
  'GroupName or UserID': "Nom du groupe ou ID de l'utilisateur",
  'AI Summary': "Résumé de l'IA",
  'Chronic / Worst Performer Details':
    'Chronique / détails sur les pires performances',
  'Triage Notes': 'Notes de triage',
  'Search By': 'Recherche par',
  'Serial Number': 'Número de serie',
  'Tag': 'Étiquette',
  'Temporarily add equipment': "Ajout temporaire d'équipements",
  'System': 'Système',
  'Machine Name': 'Nom de la machine',
  'No data is available': "Aucune donnée n'est disponible",
  'Connections': 'Connexions',
  'Non-Connected': 'Non connecté',
  'Part Recommendations': 'Recommandations partielles',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    "Veuillez attendre la mise à jour quotidienne.  La page sera automatiquement rafraîchie lorsqu'elle sera complétée.",
  'Install Date': "Date d'installation",
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    "La mise à jour quotidienne de l'ACDE KB est en cours. La navigation des pièces n'est pas disponible. L'indicateur RepairAction n'est pas non plus disponible. Veuillez réessayer lorsque le contenu aura été téléchargé.",
  'No Equipment data available for the Endpoint {{endPointId}}.':
    "Aucune donnée sur l'équipement n'est disponible pour l'extrémité {{endPointId}}.",
};

export default translation;
