import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from './IconButton';
import BottomModalSheet from './utility/BottomModalSheet';
import SectionHeading from './SectionHeading';
import SystemsCard from './cards/SystemsCard';
import { useTranslation } from 'react-i18next';

interface Props {
  isBottomsheetOpen: boolean;
  onSheetClose: () => void;
  data?: {
    title: string;
    value: string | JSX.Element;
    extraComp?: React.ReactElement;
  }[];
  children?: React.ReactNode;
}

const GenericModalSheet = (props: Props) => {
  const { isBottomsheetOpen, onSheetClose, data } = props;
  const { t } = useTranslation();
  const header = (
    <div className=" m-4">
      <IconButton
        icon={CloseOutlinedIcon}
        onClick={onSheetClose}
        className=" p-0"
      />
    </div>
  );
  return (
    <BottomModalSheet
      isOpen={isBottomsheetOpen}
      onClose={onSheetClose}
      header={header}
      disableDrag={true}
    >
      <div className=" mx-4 h-full">
        {data?.map(({ title, value, extraComp }) => {
          if (value && value !== 'N/A') {
            return (
              <div key={title} className="mb-3">
                <div className="flex flex-row">
                  <SectionHeading title={t(title)} withMargins={false} />
                  {extraComp}
                </div>
                <SystemsCard iconColor="steel">{value}</SystemsCard>
              </div>
            );
          }
          return null;
        })}
        {props?.children}
      </div>
    </BottomModalSheet>
  );
};

export default GenericModalSheet;
