import { useTranslation } from 'react-i18next';
import RepairAction from '../../../Models/RepairAction';
import CardPrimary from '../../../components/cards/CardPrimary';

import { wrap } from 'comlink';
import { useNavigate } from 'react-router-dom';
import {
  SHOULD_SINGLE_SEARCH_BE_PERFORMED,
  fixedEfcId,
  lazyLoadLimit,
} from '../../../constants';
import { useLiveQuery } from 'dexie-react-hooks';
import db from '../../../index-db';
import LazyLoader from '../../../components/utility/LazyLoader';
import useSearchGetResults from '../../../hooks/useSearchGetResults';

import { RepairSearchWorkerType } from '../../../worker/repairSearch/searchWorker';
import { RepairSearchCountWorkerType } from '../../../worker/repairSearch/searchCountWorker';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import { useToast } from '../../../components/toast/ToastProvider';

interface Props {
  handleRepairsResultsLoaded: (count: number) => void;
  renderRepairResults: boolean;
  totalResultsCount: number;
  areAllResultsLoaded: boolean;
}

const fetcher = async (
  regex: RegExp,
  offset: number,

  selectedFilter?: string
) => {
  const worker = new Worker(
    new URL('../../../worker/repairSearch/searchWorker.ts', import.meta.url),
    { name: 'repairWorker' }
  );

  const workerApi = wrap<RepairSearchWorkerType>(worker);

  try {
    const results = await workerApi.search({
      moduleFilter: selectedFilter,
      totalRes: false,
      offset: offset,
      regex,

      limit: lazyLoadLimit,
    });

    worker.terminate();
    return results;
  } catch (e) {
    console.log(e);
    worker.terminate();
    return [];
  }
};
const countFetcher = async (regex: RegExp, selectedFilter?: string) => {
  const worker = new Worker(
    new URL(
      '../../../worker/repairSearch/searchCountWorker.ts',
      import.meta.url
    ),
    { name: 'repairCountWorker' }
  );
  const workerApi = wrap<RepairSearchCountWorkerType>(worker);

  try {
    const resultCount = await workerApi.searchCount({
      moduleFilter: selectedFilter,
      totalRes: true,
      offset: null,
      regex,
      limit: undefined,
    });
    worker.terminate();
    if (typeof resultCount === 'number') {
      return resultCount;
    }
    return 0;
  } catch (e) {
    worker.terminate();
    return 0;
  }
};

const RAResults = (props: Props) => {
  const { t } = useTranslation();
  const {
    handleRepairsResultsLoaded,
    renderRepairResults,
    totalResultsCount,
    areAllResultsLoaded,
  } = props;
  const { pushToast } = useToast();

  const CustomRepairId = useLiveQuery(async () => {
    const foundEfcId = await db.efcs
      .where('EfcCodeStr')
      .equalsIgnoreCase(fixedEfcId)
      .first();
    return foundEfcId?.Id;
  });

  //Module Id, Name, Time to fix, Rpair complexity,part Number
  const navigate = useNavigate();

  const activeSearch = useAppSelector(selectActiveSearch);

  //RA logic
  const {
    results: data,
    onContentVisible,
    isLoading,
  } = useSearchGetResults({
    onResultLoaded: handleRepairsResultsLoaded,

    uniqueIdentifier: 'Id',
    fetcher,
    countFetcher,
    from: 'repairs',
    shouldSearchLogicRun: true,
  });

  const onCardClick = (id: number) => () => {
    if (id && CustomRepairId) {
      navigate(`/repair/${id}/${CustomRepairId}/${false}`);
    } else {
      pushToast({
        message: t(
          'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.'
        ),
      });
    }
  };
  if (!renderRepairResults) return null;
  if (isLoading) {
    return (
      <div className="py-4 text-center text-lg font-medium text-concrete">
        {t('Loading')}...
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="px-4">
        {t('No results Or Multiple Results, please narrow your search')}
      </div>
    );
  }

  if (
    data.length === 1 &&
    totalResultsCount === 1 &&
    localStorage.getItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED) === 'true'
  ) {
    if (
      (activeSearch?.scope === 'everywhere' && areAllResultsLoaded) ||
      activeSearch?.scope !== 'everywhere'
    ) {
      localStorage.setItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED, 'false');
      onCardClick(data[0].Id)();
    }
  }

  return (
    <>
      {renderRepairResults ? (
        <>
          {data?.map((repairAction: RepairAction, index) => (
            <LazyLoader
              onContentVisible={onContentVisible}
              lastItemIndex={data.length - 1 === index}
              key={repairAction.Id}
            >
              <CardPrimary
                title={repairAction.Name}
                description={repairAction.Description}
                blockLetter={repairAction.RepairComplexity}
                afterPartitionPrimaryTitle={t('Repair time')}
                afterPartitionSecondaryTitle={t('Repair Id')}
                afterPartitionPrimaryDescription={t('min', {
                  count: Number(repairAction.TimeToFix.toFixed(2)),
                })}
                afterPartitionSecondaryDescription={repairAction.Id.toString()}
                onCardClick={onCardClick(repairAction.Id)}
                extraTitleSubtitle={
                  <div>
                    <div className="text-center font-medium text-steel">
                      Module Id
                    </div>
                    <div className="text-center text-lg font-bold">
                      {repairAction.ModuleId}
                    </div>
                  </div>
                }
              />
            </LazyLoader>
          ))}
        </>
      ) : null}
    </>
  );
};

export default RAResults;
