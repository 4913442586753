import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '../app/hooks';
import { escapeRegExp } from '../utils';
import { selectActiveSearch } from '../app/selectors';

import { SearchScope } from '../types';

interface Props<Type> {
  onResultLoaded: (count: number) => void;
  uniqueIdentifier: string;
  fetcher: (
    regex: RegExp,
    offset: number,
    selectedFilter?: string
  ) => Promise<number | Type[] | undefined>;
  countFetcher: (regex: RegExp, selectedFilter?: string) => Promise<number>;
  from: SearchScope;
  shouldSearchLogicRun: boolean;
}

interface FetchedData {
  [key: string]: any;
}

const useSearchGetResults = <Type extends FetchedData>(props: Props<Type>) => {
  const {
    onResultLoaded,
    fetcher,
    countFetcher,
    uniqueIdentifier,
    shouldSearchLogicRun,
    from,
  } = props;
  const activeSearch = useAppSelector(selectActiveSearch);
  const [fetchedResults, setFetchedResults] = useState<Type[]>([]);
  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  const [tempRes, setTempRes] = useState<Type[] | undefined>(undefined);
  const [offset, setOffset] = useState(0);
  const selectedFilter = activeSearch?.filters?.Module?.value as
    | undefined
    | string;

  const isScopeMatching =
    activeSearch?.scope === from || activeSearch?.scope === 'everywhere';
  const shouldEfcSearch =
    isScopeMatching && from === 'efc codes' && selectedFilter;
  const shouldSearch =
    shouldSearchLogicRun &&
    isScopeMatching &&
    (activeSearch?.searchTerm?.trim()?.length ?? 0) >= 1;
  useEffect(() => {
    (async () => {
      if (shouldSearch || shouldEfcSearch) {
        const regex = new RegExp(escapeRegExp(activeSearch?.searchTerm), 'i');
        const count = await countFetcher(regex, selectedFilter);
        setResultsCount(count);
      } else {
        setResultsCount(0);
      }
    })();
  }, [selectedFilter, activeSearch?.searchTerm, shouldSearch]);

  useEffect(() => {
    (async () => {
      if (shouldSearch || shouldEfcSearch) {
        const regex = new RegExp(escapeRegExp(activeSearch?.searchTerm), 'i');

        const results = await fetcher(regex, offset, selectedFilter);

        if (Array.isArray(results)) {
          setTempRes(results);

          setFetchedResults((currentFetchedResults) => {
            if (
              (results?.[0]?.[uniqueIdentifier] as keyof Type) !==
              (tempRes?.[0]?.[uniqueIdentifier] as keyof Type)
            ) {
              return [...currentFetchedResults, ...results];
            } else {
              return currentFetchedResults;
            }
          });
        }
      } else {
        setTempRes([]);
        setFetchedResults([]);
      }
    })();
  }, [
    selectedFilter,
    offset,
    activeSearch?.searchTerm,
    uniqueIdentifier,
    shouldSearch,
  ]);

  const onContentVisible = useCallback(() => {
    setOffset(fetchedResults.length);
  }, [fetchedResults.length]);

  useEffect(() => {
    if (resultsCount !== undefined) {
      onResultLoaded(resultsCount);
    }
  }, [onResultLoaded, resultsCount]);

  return {
    results: fetchedResults,
    onContentVisible,
    isLoading: tempRes?.length === undefined,
  };
};

export default useSearchGetResults;
