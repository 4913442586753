import React from 'react';
import { useTranslation } from 'react-i18next';
import { wrap } from 'comlink';

import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';

import ActionableListItem from '../../../components/ActionableListItem';
import useNavigateToPart from '../../../hooks/useNavigateToPart';

import LazyLoader from '../../../components/utility/LazyLoader';
import useSearchGetResults from '../../../hooks/useSearchGetResults';

import {
  SHOULD_SINGLE_SEARCH_BE_PERFORMED,
  lazyLoadLimit,
} from '../../../constants';
import { PartSearchCountWorkerType } from '../../../worker/partSearch/searchCountWorker';
import { PartSearchWorkerType } from '../../../worker/partSearch/searchWorker';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import useCheckUserIsPartner from '../../../hooks/useCheckUserIsPartner';

interface Props {
  onRaIconClick: (repairs: string, searchTerm: string) => void;
  handlePartResultsLoaded: (count: number) => void;
  renderPartResults: boolean;
  totalResultsCount: number;
  areAllResultsLoaded: boolean;
}

const fetcher = async (
  regex: RegExp,
  offset: number,

  selectedFilter?: string
) => {
  const worker = new Worker(
    new URL('../../../worker/partSearch/searchWorker.ts', import.meta.url),
    { name: 'partWorker' }
  );

  const workerApi = wrap<PartSearchWorkerType>(worker);

  try {
    const results = await workerApi.search({
      moduleFilter: selectedFilter,
      totalRes: false,
      offset: offset,
      regex,
      limit: lazyLoadLimit,
    });

    worker.terminate();
    return results;
  } catch (e) {
    console.log(e);
    worker.terminate();
    return [];
  }
};
const countFetcher = async (regex: RegExp, selectedFilter?: string) => {
  const worker = new Worker(
    new URL('../../../worker/partSearch/searchCountWorker.ts', import.meta.url),
    { name: 'partCountWorker' }
  );
  const workerApi = wrap<PartSearchCountWorkerType>(worker);

  try {
    const resultCount = await workerApi.searchCount({
      moduleFilter: selectedFilter,
      totalRes: true,
      offset: null,
      regex,
      limit: undefined,
    });
    worker.terminate();
    if (typeof resultCount === 'number') {
      return resultCount;
    }
    return 0;
  } catch (e) {
    worker.terminate();
    return 0;
  }
};

function PartResults(props: Props) {
  const {
    onRaIconClick,
    handlePartResultsLoaded,
    renderPartResults,
    areAllResultsLoaded,
    totalResultsCount,
  } = props;
  const { t } = useTranslation();
  const isPartner = useCheckUserIsPartner();
  //PARTS
  const {
    results: data,
    onContentVisible,
    isLoading,
  } = useSearchGetResults({
    onResultLoaded: handlePartResultsLoaded,
    uniqueIdentifier: 'MaterialId',

    fetcher,
    countFetcher,
    from: 'parts',
    shouldSearchLogicRun: !isPartner,
  });
  const navigateToPart = useNavigateToPart();
  const activeSearch = useAppSelector(selectActiveSearch);
  if (!renderPartResults) return null;

  if (isLoading) {
    return (
      <div className="py-4 text-center text-lg font-medium text-concrete">
        {t('Loading')}...
      </div>
    );
  }

  if (data.length === 0) {
    return <div className="px-4">{t('No results')}</div>;
  }

  if (
    data.length === 1 &&
    totalResultsCount === 1 &&
    localStorage.getItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED) === 'true'
  ) {
    if (
      (activeSearch?.scope === 'everywhere' && areAllResultsLoaded) ||
      activeSearch?.scope !== 'everywhere'
    ) {
      localStorage.setItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED, 'false');
      navigateToPart(data[0].MaterialId);
    }
  }
  const onIconClick =
    (searchTerm: string) =>
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      onRaIconClick('repairs', searchTerm);
    };
  return (
    <>
      {renderPartResults ? (
        <>
          {data.map((result, index) => (
            <LazyLoader
              onContentVisible={onContentVisible}
              lastItemIndex={data.length - 1 === index}
              key={result.MaterialId}
            >
              <ActionableListItem
                primaryAction="navigate"
                title={result.MaterialId}
                subTitle={
                  result.ManufactureNumber &&
                  `(${t('mfg')}: ${result.ManufactureNumber})`
                }
                subText={result.PartName}
                {...(result.HasRepairActions
                  ? {
                      intermediateIcon: BuildCircleOutlinedIcon,
                      onClickIntermediateIcon: onIconClick(result.MaterialId),
                    }
                  : {})}
                onClick={() => navigateToPart(result.MaterialId)}
              ></ActionableListItem>
            </LazyLoader>
          ))}
        </>
      ) : null}
    </>
  );
}

export default PartResults;
