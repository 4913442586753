import React from 'react';
import ActionableListItem from '../../components/ActionableListItem';
import ManualEndPoint from '../../Models/ManualEndPoint';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import db from '../../index-db';

interface Props {
  manualEndPoint: ManualEndPoint;
  onClick: () => void;
  onItemDeleted: () => void;
  onEditClick: (e: React.SyntheticEvent) => void;
}

function EquipmentListItem(props: Props) {
  const title = `Serial: ${props.manualEndPoint.serialNumber}`;

  const deleteManualEndPoint = async () => {
    await db.manualEndPoints.delete(props.manualEndPoint.id);
    props.onItemDeleted();
  };

  return (
    <ActionableListItem
      key={props.manualEndPoint.id}
      icon={CloseOutlinedIcon}
      primaryAction="navigate"
      onClick={props.onClick}
      onClickIcon={() => deleteManualEndPoint()}
      title={title}
      secondarySubText={props.manualEndPoint?.userTag}
      subText={props.manualEndPoint.prodDesc}
      isChronic={
        props.manualEndPoint?.chronicIndicator?.toLowerCase() === 'yes'
      }
      onContentClick={props.onEditClick}
      image={
        props?.manualEndPoint?.serviceInsights?.length > 0
          ? './lightBulbBrain.png'
          : props?.manualEndPoint?.preventiveInsights?.length > 0
          ? './lightBulbGear.png'
          : ''
      }
    />
  );
}

export default EquipmentListItem;
