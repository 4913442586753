import { useTranslation } from 'react-i18next';
import Insights from './Insights';
import EndPoint from '../../Models/EndPoint';
import { CallToActionType, InsightType } from '../../Models/Enumerations';
import Observations from './Observations';
import NoData from '../../components/utility/NoData';
import Loading from '../../components/utility/Loading';
import RecommendedPart from '../../Models/RecommendedPart';
import AiSummaryContent from './AiSummaryContent';
import PartRecommendations from './PartRecommendations';
import CallToActionSettings from '../../Models/CallToActionSettings';
import { useEffect, useState } from 'react';
import { stringToHash } from '../../utils';
import useLogging from '../../hooks/useLogging';
import db from '../../index-db';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';

interface Props {
  equipmentData: EndPoint;
  serviceTaskId?: string;
  callToActionSettings?: CallToActionSettings[];
  isEquipmentLoading: boolean;
}

interface CallToActionInterface {
  preventiveActions: CallToActionSettings[] | [];
  serviceActions: CallToActionSettings[] | [];
  aiSummaryAction?: CallToActionSettings;
  observationAction?: CallToActionSettings;
  partAction?: CallToActionSettings;
}

function CurrentEquipment(props: Props) {
  const { t } = useTranslation();
  const logging = useLogging();
  let recommendedParts = new Array<RecommendedPart>();
  if (props.equipmentData && props.equipmentData.serviceInsights) {
    props.equipmentData.serviceInsights.forEach((svcInsight) => {
      svcInsight.recommendedParts.forEach((part) => {
        recommendedParts.push(part);
      });
    });
  }
  const [callToActionState, setCallToActionState] =
    useState<CallToActionInterface | null>(null);

  useEffect(() => {
    async function logEquipqmentData() {
      if (Object.entries(props.equipmentData).length > 0) {
        const hashedKey = stringToHash(props.equipmentData.generatedSummary);
        const audioExists =
          (await db.audioAiSummary.where('key').equals(hashedKey).count()) == 0
            ? '0'
            : '1';
        const logParams: LogParams[] = [
          {
            key: LogType.Page,
            value: LogType.Equipment,
          },
          {
            key: LogType.EndpointId,
            value: props.equipmentData.id,
          },
          {
            key: LogType.ServiceTaskId,
            value: props.serviceTaskId || '',
          },
          {
            key: LogType.SerialNumber,
            value: props.equipmentData.serialNumber || '',
          },
          {
            key: 'SI Count',
            value: String(props.equipmentData.serviceInsights?.length),
          },
          {
            key: 'PI Count',
            value: String(props.equipmentData.preventiveInsights?.length),
          },
          {
            key: 'AI Summary',
            value: String(
              props.equipmentData.generatedSummary?.length != 0 ? 1 : 0
            ),
          },
          {
            key: 'Parts',
            value: String(recommendedParts?.length),
          },
          {
            key: 'Obs',
            value: String(
              props.equipmentData.endpointStatistics != null ||
                props.equipmentData.chronicDetails != null ||
                props.equipmentData.wpTrackTriageNotes?.length > 0 ||
                props.equipmentData.naTriageNotes?.length > 0
                ? 1
                : 0
            ),
          },
          {
            key: 'TTS',
            value: audioExists,
          },
        ];
        logging('info', 'Reached equipment page', '', false, logParams);
      }
    }
    logEquipqmentData();
  }, [props.equipmentData]);

  useEffect(() => {
    const preventiveActions: CallToActionSettings[] = [];
    const serviceActions: CallToActionSettings[] = [];
    let aiSummaryAction: CallToActionSettings | undefined = undefined;
    let observationAction: CallToActionSettings | undefined = undefined;
    let partAction: CallToActionSettings | undefined = undefined;
    if (props.callToActionSettings) {
      props.callToActionSettings?.forEach((actionSetting) => {
        switch (actionSetting.callToActionType) {
          case CallToActionType.PreventiveInsight:
            preventiveActions.push(actionSetting);
            break;
          case CallToActionType.ServiceInsight:
            serviceActions.push(actionSetting);
            break;
          case CallToActionType.AiSummary:
            aiSummaryAction = actionSetting;
            break;
          case CallToActionType.Observation:
            observationAction = actionSetting;
            break;
          case CallToActionType.Part:
            partAction = actionSetting;
            break;
        }
      });
      setCallToActionState({
        preventiveActions,
        serviceActions,
        aiSummaryAction,
        observationAction,
        partAction,
      });
    }
    return () => {
      setCallToActionState({
        preventiveActions: [],
        serviceActions: [],
        aiSummaryAction: undefined,
        observationAction: undefined,
        partAction: undefined,
      });
    };
  }, [props.callToActionSettings]);

  if (props.isEquipmentLoading) {
    return <Loading />;
  }

  if (
    (!props.equipmentData.serviceInsights?.length &&
      !props.equipmentData.preventiveInsights?.length &&
      !props.equipmentData?.endpointStatistics &&
      !props.equipmentData?.generatedSummary &&
      !props.equipmentData?.wpTrackTriageNotes &&
      !props.equipmentData?.naTriageNotes &&
      !props.equipmentData?.chronicDetails) ||
    !props?.equipmentData
  ) {
    return <NoData />;
  }

  return (
    <>
      <div>
        {props.equipmentData && (
          <div>
            {props.equipmentData.serviceInsights &&
              props.equipmentData.serviceInsights.length > 0 &&
              callToActionState && (
                <Insights
                  insights={props.equipmentData.serviceInsights}
                  title={t('Service Insights')}
                  insightType={InsightType.Service}
                  equipmentData={props.equipmentData}
                  serviceTaskId={props.serviceTaskId}
                  callToActionSettings={
                    callToActionState.serviceActions.length
                      ? callToActionState.serviceActions
                      : undefined
                  }
                />
              )}
            {props.equipmentData.preventiveInsights &&
              props.equipmentData.preventiveInsights.length > 0 &&
              callToActionState && (
                <Insights
                  insights={props.equipmentData.preventiveInsights}
                  title={t('Preventive Insights')}
                  insightType={InsightType.Preventive}
                  equipmentData={props.equipmentData}
                  serviceTaskId={props.serviceTaskId}
                  callToActionSettings={
                    callToActionState.preventiveActions.length
                      ? callToActionState.preventiveActions
                      : undefined
                  }
                />
              )}
            <div className="mx-4">
              <AiSummaryContent
                summaryText={props.equipmentData?.generatedSummary}
                endpointId={props.equipmentData.id}
                isRead={
                  callToActionState?.aiSummaryAction?.callToActionType ===
                    CallToActionType.AiSummary || !props.serviceTaskId
                }
                taskNumber={props.serviceTaskId}
              />
              <PartRecommendations
                recommendedParts={recommendedParts}
                endpointId={props.equipmentData.id}
                isRead={
                  callToActionState?.partAction?.callToActionType ===
                    CallToActionType.Part || !props.serviceTaskId
                }
                taskNumber={props.serviceTaskId}
              />
            </div>

            <Observations
              equipmentData={props.equipmentData}
              isRead={
                callToActionState?.observationAction?.callToActionType ===
                  CallToActionType.Observation || !props.serviceTaskId
              }
              taskNumber={props.serviceTaskId}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CurrentEquipment;
