import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CollapsibleCollection from '../../components/collapsible/CollapsibleCollection';
import { useToast } from '../../components/toast/ToastProvider';
import db from '../../index-db';
import { IDType, TimeLineEventType } from '../../Models/Enumerations';
import Module from '../../Models/Module';
import ModuleLinks from '../../Models/ModuleLinks';
import Part from '../../Models/Part';
import RALinks from '../../Models/RALinks';
import SubStep from '../../Models/SubStep';
import { usePage } from '../../PageProvider';
import ModLinks from './ModuleLinks';
import Parts from './Parts';
import RepairActionDescription from './RepairActionDescription';
import RepairActionLinks from './RepairActionLinks';
import Substeps from './Substeps';
import FeedbackScreen from '../Feedback/FeedbackScreen';
import useAddToRecentlyViewed from '../../hooks/useAddToRecentlyViewed';
import useAddToSavedItems from '../../hooks/useAddToSavedItems';
import PageNotFound from '../../PageNotFound';
import useCheckUserIsPartner from '../../hooks/useCheckUserIsPartner';
import RepairPageAction from './RepairPageAction';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import TimelineEvent from '../../Models/TimeLineEvent';
import * as uuid from 'uuid';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import Task from '../../Models/Task';
import EndPoint from '../../Models/EndPoint';

function RepairAction() {
  interface IRepairActionData {
    partRecords: Part[];
    substepRecords: SubStep[];
    repairActionLinks: RALinks[];
    moduleLinks: ModuleLinks[];
    raDescription: string;
    raName: string;
    moduleId: number;
    efcCode?: string;
    efcRaCount?: number;
  }

  interface NoEndpointData {
    id: string;
    serialNumber: string;
  }

  const { pushToast } = useToast();
  const logging = useLogging();
  const { t } = useTranslation();
  const addToRecentlyViewed = useAddToRecentlyViewed();
  const addToSavedItems = useAddToSavedItems();
  const [allCollapsed] = useState(false);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const params = useParams();
  const [isWrongURL, setIsWrongURL] = useState(false);
  const repairActionId = Number(params.idGeneric);
  const efcId = Number(params.efcId);
  const api = useApi();
  const defaultNoEndpointValue = {
    id: 'No Endpoint',
    serialNumber: '',
  };
  const [selectedEndpoint, setSelectedEndpoint] = useState<
    EndPoint | NoEndpointData
  >(defaultNoEndpointValue);
  const [raCompleteDisplayTime, setRACompleteDisplayTime] = useState('');

  const isPartner = useCheckUserIsPartner();

  const externalSource = params.externalSource ? params.externalSource : '';
  const repairAction = useLiveQuery(async () => {
    return db.raactions.get(repairActionId);
  }, [repairActionId]);

  const getRepairHeading = useCallback(() => {
    return repairAction?.GetHeading() ?? '';
  }, [repairAction]);

  const isSaved: boolean | undefined = useLiveQuery(async () => {
    const raRecord = await db.savedItems
      .where({
        IdGeneric: repairActionId,
        IdType: IDType.REPAIR_ACTION,
      })
      .toArray();
    return raRecord.length ? true : false;
  });

  const fetchOpenServiceTasks = async (): Promise<Task[]> => {
    return (await api.fetchJson(
      '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
    )) as Task[];
  };

  usePage(
    () => ({
      getTitle: getRepairHeading,
      belongsToNavBarItem: 'Menu',
      rightButtons: [
        { icon: CommentOutlinedIcon, onClick: () => setFeedbackOpen(true) },
        {
          icon: isSaved ? BookmarkIcon : BookmarkBorderOutlinedIcon,
          iconColor: isSaved ? 'text-cobalt' : undefined,
          onClick: () => {
            addToSavedItems(
              repairActionId,
              IDType.REPAIR_ACTION,
              getRepairHeading(),
              pushToast,
              efcId.toString()
            );
          },
        },
      ],
      showBackButton: true,
    }),
    [isSaved, repairActionId, pushToast]
  );

  useEffect(() => {
    (async () => {
      const raRecord = await db.raactions.get(repairActionId);
      if (raRecord) {
        setIsWrongURL(false);
        addToRecentlyViewed(
          repairActionId,
          IDType.REPAIR_ACTION,
          efcId.toString()
        );
      } else {
        setIsWrongURL(true);
      }
    })();
  }, [repairActionId]);

  const repairActionsData = useLiveQuery(async () => {
    const raRecord = await db.raactions.get(repairActionId);
    if (raRecord) {
      const repairActionData = {} as IRepairActionData;
      if (raRecord.PartNumbers != null) {
        repairActionData.partRecords = await db.parts
          .where('MaterialId')
          .anyOf(raRecord.PartNumbers)
          .toArray();
      }

      repairActionData.raDescription = raRecord.Description;
      repairActionData.raName = raRecord.Name;
      repairActionData.moduleId = raRecord.ModuleId;

      if (raRecord.SubSteps != null) {
        repairActionData.substepRecords = raRecord.SubSteps;
      }

      let moduleRecord: Module | undefined;
      moduleRecord = await db.modules.get(raRecord.ModuleId);
      if (
        moduleRecord &&
        moduleRecord !== null &&
        moduleRecord.ModuleLinks !== null &&
        moduleRecord.ModuleLinks.length !== 0
      ) {
        repairActionData.moduleLinks = moduleRecord.ModuleLinks;
      }

      if (
        raRecord.RepairActionLinks !== null &&
        raRecord.RepairActionLinks.length !== 0
      ) {
        repairActionData.repairActionLinks = raRecord.RepairActionLinks;
      }

      const efcRecord = await db.efcs.get(efcId);
      if (efcRecord) {
        repairActionData.efcCode = efcRecord.EfcCodeStr;
        repairActionData.efcRaCount = efcRecord.RepairActionData?.length;
      }

      return repairActionData;
    }
  });

  useEffect(() => {
    (async () => {
      if (repairActionsData) {
        const logParams: LogParams[] = [
          {
            key: LogType.Page,
            value: LogType.RepairAction,
          },
          {
            key: LogType.RaId,
            value: repairActionId.toString(),
          },
          {
            key: LogType.EfcCode,
            value: repairActionsData?.efcCode ?? '',
          },
          {
            key: LogType.EfcRaCount,
            value: repairActionsData?.efcRaCount?.toString() ?? '',
          },
          {
            key: LogType.ExternalSource,
            value: externalSource,
          },
        ];

        try {
          let tasks = await fetchOpenServiceTasks();
          tasks = tasks.filter(
            (task) =>
              task.deviceSerialNumber !== null &&
              task.deviceSerialNumber !== '' &&
              task.deviceSerialNumber !== 'N/A'
          );
          await db.tasks.clear();
          await db.tasks.bulkPut(tasks);

          logging('info', `tasks fetched ${tasks.length}`, '', false, null);
          logging('info', '', '', false, logParams, true);
        } catch (error) {
          logging('info', '', '', false, logParams, true);
        }
      }
    })();
  }, [repairActionId, efcId, repairActionsData]);

  async function SaveTimeLineEvent(feedback: string) {
    const currentTime = new Date().toISOString();
    const timelineEventProperties: Record<string, string> = {
      efcId: efcId.toString(),
      feedback: feedback,
    };

    const timelineEvent = new TimelineEvent(
      uuid.v4().toString(),
      repairActionsData?.raDescription ?? '',
      selectedEndpoint.id,
      repairActionId.toString(),
      currentTime,
      TimeLineEventType[TimeLineEventType.Repair],
      timelineEventProperties
    );
    try {
      await api.postJson(
        '/dwar/api/almanac/TimelineEvent/addTimelineEvent',
        JSON.stringify(timelineEvent)
      );

      const currentDate = new Date(Date.now());
      const timestamp = new Date(currentTime);

      const diffInTime = currentDate.getTime() - timestamp.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

      const hours =
        timestamp.getHours() < 10
          ? `0${timestamp.getHours()}`
          : timestamp.getHours().toString();
      const minutes =
        timestamp.getMinutes() < 10
          ? `0${timestamp.getMinutes()}`
          : timestamp.getMinutes().toString();
      const seconds =
        timestamp.getSeconds() < 10
          ? `0${timestamp.getSeconds()}`
          : timestamp.getSeconds().toString();

      setRACompleteDisplayTime(
        `${diffInDays} days ago ${hours}:${minutes}:${seconds} ${timestamp.toDateString()}`
      );
    } catch (error) {
      logging('error', 'Unable to add RA timeline event.', error, false, null);
    }
  }

  const handleSelectedEndpoint = (selectedEndpoint?: EndPoint) => {
    if (selectedEndpoint) {
      setSelectedEndpoint(selectedEndpoint);
    } else {
      setSelectedEndpoint(defaultNoEndpointValue);
    }
  };
  return (
    <div>
      {!isWrongURL && (
        <FeedbackScreen
          feedbackType="repairAction"
          isOpen={isFeedbackOpen}
          handleClose={() => setFeedbackOpen(false)}
          handleSubmit={(raPerformed: boolean) => {
            setFeedbackOpen(false);
            if (raPerformed && selectedEndpoint.id != t('No Endpoint')) {
              SaveTimeLineEvent('Feedback');
            }
          }}
          repairActionId={repairActionId}
          moduleId={repairActionsData?.moduleId}
          efcId={efcId}
          efcCode={repairActionsData?.efcCode}
          repairActionDescription={repairActionsData?.raDescription}
          repairActionName={repairActionsData?.raName}
        />
      )}
      <RepairPageAction
        raId={repairActionId}
        efcId={efcId}
        efcCode={repairActionsData?.efcCode ?? ''}
        externalSource={externalSource}
        serialNumber={selectedEndpoint.serialNumber}
        raDescription={repairActionsData?.raDescription ?? ''}
        raName={repairActionsData?.raName ?? ''}
        moduleId={repairActionsData?.moduleId ?? 0}
        raCompleteDisplayTime={raCompleteDisplayTime}
        handleSelectedEndpoint={handleSelectedEndpoint}
      />
      {repairActionsData && (
        <div>
          <RepairActionDescription
            raDescription={t(repairActionsData.raDescription)}
          />
          <CollapsibleCollection collapsed={allCollapsed}>
            {!isPartner && repairActionsData?.partRecords && (
              <Parts
                partsData={repairActionsData?.partRecords}
                title={t('Parts')}
              />
            )}
            {repairActionsData?.substepRecords && (
              <Substeps
                substepsData={repairActionsData?.substepRecords}
                title={t('Steps')}
              />
            )}
            {repairActionsData?.repairActionLinks && (
              <RepairActionLinks
                RALinksData={repairActionsData?.repairActionLinks}
                title={t('Repair action content')}
              />
            )}
            {repairActionsData?.moduleLinks && (
              <ModLinks
                moduleLinksData={repairActionsData?.moduleLinks}
                title={t('Module content')}
              />
            )}
          </CollapsibleCollection>
        </div>
      )}

      {isWrongURL && <PageNotFound />}
    </div>
  );
}
export default RepairAction;
