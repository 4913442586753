const translation = {
  'You switched to {{ language }}': 'Beralih ke {{ language }}',
  'Indonesian': 'Indonesia',
  'Home': 'Halaman Utama',
  'Search': 'Pencarian',
  'Saved': 'Disimpan',
  'Me': 'Saya',
  'Menu': 'Menu',
  'English': 'Inggris',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'Pengaturan',
  'Language': 'Bahasa',
  'Saved Items': 'Item tersimpan',
  'My tasks': 'Pekerjaan Saya',
  'Parts': 'Suku Cadang',
  'Saved items': 'Item tersimpan',
  'Notifications': 'Pemberitahuan',
  'Help & Feedback': 'Bantuan dan Masukkan&Saran',
  'Logout': 'Keluar',
  'Add to equipment list': 'Menambahkan ke Daftar Peralatan',
  'Download for offline': 'Di download untuk penggunaan offline',
  'Repair Action': 'Aktifitas Perbaikan',
  'Repair action': 'Aktifitas Perbaikan',
  'Part': 'Part',
  'Refreshed {{time}} min ago': 'Refreshed {{time}} beberapa menit yang lalu',
  'Recently viewed': 'Baru saja dilihat',
  'View all': 'Melihat Semua',
  'Clear All': 'Bersihkan semua',
  'Expand all': 'Mengembangkan semua',
  'Collapse all': 'Menyusutkan semua',
  'Search results': 'Hasil Pencarian',
  'Repairs': 'Perbaikan - perbaikan',
  'Downloading content...': 'Sedang men-download konten...',
  'Bookmarks': 'Bookmark',
  'Downloads': 'Download',
  'Recent': 'terkini',
  'Recent codes': 'Kode terkini',
  'Saved codes': 'Kode tersimpan',
  'Updates': 'Pembaharuan',
  'Clear': 'Membersihkan',
  'Updated Content for {{item}}.': 'Isi yang diperbaharui untuk {{item}',
  '{{item}} has been saved': '{{item}} Telah disimpan',
  '{{item}} could not be saved': '{{item}} tidak bisa disimpa',
  '{{item}} has been removed from saved':
    '{{item}} telah dihapus dari data yang tersimpan',
  '{{item}} could not be removed from saved':
    'tidak dapat dihapus dari data yang tersimpan',
  'Difficulty': 'Kesulitan',
  'Repair time': 'Waktu perbaikan',
  'min_one': '1 menit',
  'min_other': '{{count}} menit',
  'min_zero': '0 menit',
  'Rating': 'Penilaian',
  'Thank you for the feedback': 'Terimakasih atas saran dan masukkannya',
  'Not relevant': 'tidak berhubungan',
  'Relevant': 'Berhubungan',
  'Fixed my issue': 'permasalahnan saya sudah diperbaiki',
  'Leave feedback': 'Silahkan beri masukan dan saran',
  'Save item': 'Menyimpan item',
  'Low': 'Rendah',
  'Medium': 'sedang',
  'High': 'Tinggi',
  'This was relevant info': 'Info berhubungan',
  'This was not relevant info': 'Info ini tidak berhubungan',
  'Add to saved items': 'Menambahkan ke item tersimpan',
  'Remove from downloads': 'Hapus dari daftar download',
  'Search AllConnect Field Support': 'Pencarian AllConnect Field Support',
  'Filters': 'Penyaringan',
  'Page not found': 'Halaman tidak ditemukan',
  'Return home': 'Kembali ke Halaman Utama',
  'Clear all bookmarks': 'Hapus semua bookmark',
  'Search all EFC codes': 'Pencarian semua kode EFC',
  'Search all parts': 'Pencarian semua part',
  'Results ({{resultsCount}})': 'Hasil ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} telah disimpan',
  'Details': 'Rincian',
  'Equipment': 'Peralatan',
  'Notes': 'Catatan',
  'Preventive Insights': 'Wawasan Pencegahan',
  'Service Insights': 'Wawasan Layanan',
  'Equipment Information': 'Informasi Peralatan',
  'Equipment ID': 'ID/Pengenal peralatan',
  'Model': 'model',
  'Terminal ID': 'Terminal ID',
  'Device serial number': 'Nomor Seri Perangkat',
  'EFC codes': 'Kode EFC',
  'Steps': 'Langkah - langkah',
  'Module content': 'konten modul',
  'Repair action content': 'konten aktifitas perbaikan',
  'Fixed issue': 'Permasalahan diselesaikan',
  'Part Number {{partId}} copied to the clipboard': 'Nomor bagian {{partId}}',
  'Relationship tree': 'Pohon hubungan',
  'Potential substitutions': 'Potensi pengganti',
  'Loading': 'Memuat',
  'Removed from saved searches': 'Dikeluarkan dari Pencarian tersimpan',
  'Added to saved searches': 'Ditambahkan ke Pencarian Tersimpan',
  'All bookmarks cleared': 'Semua bookmark dihapus',
  'No bookmarks to clear': 'Tidak ada bookmark yang dihapus',
  'All recent items cleared': 'Semua item terkini dihapus',
  'No recent items to clear': 'Tidak ada item terbaru yang dihapus',
  'You have no recently view history.':
    'Anda tidak memiliki tampilan riwayat terkini',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'Perpindahan bahasa dilakukan dalam 2 tahap. Data statis seperti label dan judul akan segera dialihkan. Data konten memerlukan koneksi online dan dapat memakan waktu hingga satu menit atau lebih bergantung pada koneksi Anda untuk pilihan bahasa baru. Hingga konten di download, aplikasi akan terus menampilkan bahasa sebelumnya',
  'Signing you in': 'Masuk',
  'Signing you out': 'Keluar',
  'Oops': 'Oops',
  'Switching language to {{ language }}': 'Merubah bahasa ke {{ language }}',
  'Data has been fetched in': 'Data telah diambil',
  'Failed to fetch data in': 'Gagal mengambil data',
  'No results': 'Tidak ada hasil',
  'URL': 'URL',
  'User Name': 'Nama Pengguna',
  'ACFS App': 'Aplikasi ACFS',
  'ACFS UI': 'ACFS UI',
  'Offline Data': 'Data offline',
  'Info': 'Informasi',
  'Repair': 'Perbaikan',
  'RepairActionCount 1': '1 aktifitas perbaikan',
  'RepairActionCount {{count}}': '{{count}} aktifitas perbaikan',
  'RepairActionCount 0': '0 aktifitas perbaikan',
  'All': 'Semua',
  'Sync': 'Sinkronisasi',
  'Last 30 days': '30 hari terakhir',
  'Not yet implemented': 'belum diimplementasikan',
  'Categories': 'Kategori',
  'Repair actions': 'aktifitas perbaikan',
  'Module': 'Modul',
  'Preferences': 'Pilihan',
  'Service Manuals': 'Manual Servis',
  'Service Bulletins': 'Buletin Layanan',
  'FCOs': 'FCOs',
  'FAQs': 'FAQs',
  'Training Content': 'Materi Pelatihan',
  'Your feedback has been sent': 'Masukan dan Saran Anda sudah dikirim',
  'Failed to send your feedback': 'Gagal mengirimkan masukan dan saran Anda',
  'Comments are required': 'Ulasan Anda dibutuhkan',
  'Comments should not be more than 100 characters':
    'Ulasan tidak lebih dari 100 karakter',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Maksimal {{numOfPhotos}} foto yang bisa di upload',
  'Add this Task - {{taskNumber}}': 'Tambahkan pekerjaan - {{taskNumber}}',
  "Other '{{inputValue}}'": "Lainnya '{{inputValue}}'",
  'Other (Type to add) / Blank': 'Lainnya (tulis untuk menambahkan) / Kosong',
  'General feedback': 'Feedback umum',
  'Feedback: EFC code - {{efcCode}}': 'Feedback: EFC code - {{efcCode}}',
  'Module: {{moduleName}}': 'Modul: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'Feedback untuk Kode EFC, Tindakan Perbaikan, atau Bagian tertentu harus dikirimkan menggunakan pilhan feedback yang tersedia pada entri tertentu.',
  'Corrective action required': 'Aktiftas perbaikan diperlukan',
  'Yes': 'Ya',
  'No': 'Tidak',
  'Task number': 'Nomor tugas',
  'Select or Type to add': 'Pilih atau tulis untuk menambahkan',
  'Comments': 'Ulasan',
  'Delete': 'Hapus',
  'Open Camera': 'Kamera aktif',
  'Upto {{maxFiles}} images can be selected':
    'hingga {{maxFiles}} gambar yang dapat dipilih',
  'Cancel': 'Batal',
  'Add': 'Tambahkan',
  'Add equipment': 'Tambah perangkat',
  'Serial number': 'Nomor seri',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Pindai kode peralatan atau masukkan nomor seri untuk melihat keterangan dan detail untuk peralatan tersebut.',
  'Last synced: {{time}} min ago':
    'Sinkronisasi terakhir: {{time}} beberapa menit yang lalu',
  'Send': 'Mengirimkan',
  "Task Number must be provided if 'Corrective action required' is Yes":
    "Task number harus diberikan jika 'Tindakan korektif diperlukan' Ya",
  'Time to fix (minutes)': 'Waktu perbaikan (minutes)',
  'Time to fix should not be more than 999':
    'Waktu perbaikan tidak lebih dari 999',
  'Please enter positive numbers only': 'Silahkan masukkan angka',
  'Please enter numbers only': 'Silahkan masukan angka',
  'Clear filters': 'Bersihkan penyaringan',
  'Show results': 'Tunjukan hasil',
  'Your feedback has been submitted': 'Feedback Anda telah diajukan',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'Kode EFC berkaitan dengan enrichment yang sedang aktif. Jika Anda ingin meninggalkan tambahan feedback silahkan pilih "Tinggalkan Feedback"',
  'Products': 'Produk',
  'Product Number {{productId}} copied to the clipboard':
    'Nomor Produk {{productId}} disalin ke clipboard',
  'View Groups': 'Melihat Grup',
  'Search all products': 'Pencarian semua produk',
  'Current': 'Saat ini',
  'History': 'Riwayat',
  'No equipment data found for serial number {{number}}.':
    'Data perangkat tidak ditemukan untuk nomor seri {{number}}',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Tidak dapat mengambil data peralatan untuk nomor seri {{number}}',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Tidak dapat mengambil data peralatan. Kembali ke data offline',
  'Unable to retrieve service task. Falling back to offline data.':
    'Tidak dapat mengambil Service Task. Kembali ke data offline',
  'Please wait while data is being loaded in {{language}}':
    'Silahkan tunggu sementara data sedang dikumpulkan {{language}}',
  'Errors': 'Error',
  'Tasks': 'Pekerjaan',
  'History Parts': 'Riwayat suku cadang',
  'Unable to retrieve Service Task history.':
    'Tidak dapat mengambil riwayat Service Task',
  'Recommended Parts': 'Suku cadang yang direkomendasikan',
  'Service Task Details': 'Detail Service Task',
  'Task Number': 'Task Number',
  'Task Status': 'Status pekerjaan',
  'Problem': 'Problem',
  'Task Type': 'Tipe pekerjaan',
  'Customer': 'Customer',
  'Effective Date': 'Tanggal efektif',
  'Device Description': 'Penjelasan perangkat',
  'Device Serial Number': 'Nomor seri perangkat',
  'EndPoint Id': 'EndPoint Id',
  'Service Task Notes {{length}}.': 'Catatan Service Task {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'Suku cadang {{partId}} tidak ditemukan di DNSpares',
  'Number': 'Nomor',
  'Site Number': 'Nomor Situs',
  'Address': 'Alamat',
  'Contact Date': 'Tanggal Kontak',
  'Contact Name': 'Nam kontak',
  'Contact Email': 'Email kontak',
  'Contact Phone': 'telepon kontak',
  'Contract Hours': 'Jam perjanjian',
  'Site Messages:': 'Pesan Situs:',
  'Type': 'Tipe',
  'Product': 'Produk',
  'Desc': 'Deskripsi',
  'Task Number should not be more than 30 characters':
    'Nomor pekerjaan tidak lebih dari 30 karakter',
  'Select Photos': 'Pilih foto',
  'Session Expired': 'Sesi berakhir',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'Sesi Anda telah berakhir ketika offline. Silahkan menghubungkan kembali perangkat Anda kemudian tekan tombol login untuk membawa Anda kembali ke Halaman Utama',
  'Login': 'Login',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Silahkan berikan feedback Anda untuk meningkatkan konten di ACFS yang tidak berkaitan dengan EFC atau aktifitas perbaikan',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Silahkan berikan saran dan masukan Anda untuk meningkatkan konten pada kode EFC',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'Apakah ada langkah perbaikan yang terlewat? Misalnya, peringkat bintang dan waktu pengerjaan ditambah? Silahkan berikan saran dan masukan Anda untuk meningkatkan konten pada solusi perbaikan dan yang tergabung dalam EFC',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Pilih "Ya" jika Anda merekomendasikan bahwa harus dilakukan perubahan oleh Administrator',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'Feedback tidak diwajibkan. Gunakna Bahasa Inggris jika memungkinkan. Feedback digunakan untuk meningkatkan konten di ACFS. Feedback dapat dibagikan dengan managemen untuk memastikan kualitasnya. Adminstrator akan menghubungi Anda untuk tambahan informasi lebih rinci dalam upaya peningkatan konten atau keperluan bisnis lainnya.',
  'OfflineMessage':
    'Saat ACFS dalam mode offline, ada beberapa konten yang tidak akan tersedia karena sifat konten, frekuensi pembaruan, dan pembatasan penyimpanan di dalam perangkat. Karena itu',
  'OfflineMessage1':
    'Pada Halaman Utama dan Halaman Saya, sesi Pekerjaan Saya hanya akan menampilkan tugas yang belum selesai pada perangkat',
  'OfflineMessage2':
    'Pada halaman terkait Peralatan (Peralatan, Detail, Suku Cadang), peralatan dan service task akan ditampilkan dari data yang tersedia di perangkat',
  'OfflineMessage3':
    'Halaman penjelasana hanya akan menampilkan catatan service task terbuka pengguna yang tersedia di perangkat yang sesuai dengan peralatan',
  'OfflineMessage4':
    'Menambahkan fungsionalitas Peralatan di Halaman Saya dan halaman Peralatan tidak akan berfungsi karena peralatan manual tidak dapat diambil berdasarkan nomor seri yang diberikan oleh pengguna',
  'Feedback: Repair # {{repairActionId}}':
    'Feedback: Perbaikan # {{repairActionId}}',
  'Training': 'Pelatihan',
  'General Help': 'Bantuan Umum',
  'Offline Disclaimer': 'Penafian Offline',
  'Nothing here yet': 'Tidak ditemukan apapun disini',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Tidak dapat mengambil tugas. Kembali ke data offline',
  'Category': 'Kategori',
  'Family': 'Family',
  'Skill level required': 'Tingkat keterampilan yang diperlukan',
  'Sort by': 'Diurutkan berdasarkan',
  'Subsystem': 'Subsistem',
  'Recent records': 'Catatan terbaru',
  'Systems': 'Sistem',
  'Task - {{taskNumber}}': 'Pekerjaan - {{taskNumber}}',
  'Add New': 'Tambah baru',
  'Everywhere': 'Dimanapun',
  'Keywords': 'kata kunci',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'Halaman detail berisi konten hanya bila ada panggilan aktif yang ditetapkan ke pengguna untuk peralatan ini',
  'OfflineMessage5':
    'Gambar dalam konten aplikasi seperti gambar EFC, gambar langkah - langkah Tindakan Perbaikan, gambar part tidak akan muncul jika pengguna sedang offline',
  'Module(EFC only)': 'Modul(hanya EFC)',
  'German': 'Jerman',
  'Francias': 'Prancis',
  'Polish': 'Polandia',
  'Espanol': 'Spanyol',
  'Chinese': 'China',
  'Thai': 'Thailand',
  'Vietnamese': 'Vietnam',
  'Portuguese': 'Portugis',
  'Turkish': 'Turki',
  'If no stock, replaces': 'Jika tidak ada stok, ganti',
  'If no stock, replaced by': 'Jika tidak ada stok, diganti dengan',
  'Replaces': 'Menggantikan',
  'Replaced by': 'Diganti oleh',
  'Repair Id': 'Id Perbaikan',
  'View Child Ids': 'Melihat Id Cabang',
  'Device Id': 'Id Perangkat',
  'Equipment with serial number {{number}} added Succesfully.':
    'Peralatan dengan nomor seri {{number}} berhasil ditambahkan.',
  'mfg': 'manufacturing number',
  'Reporting System': 'Sistem Pelaporan',
  'Update': 'memperbarui',
  'No Endpoint': 'tidak ada Endpoint',
  'Repair Action performed': 'tindakan perbaikan yang dilakukan',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Pembaruan: Nama pengguna sekarang terlihat dalam riwayat tugas. Anda akan melihat nama pengguna lain, dan yang lain akan melihat nama Anda. Perubahan ini membantu mengidentifikasi pengguna sebelumnya, sehingga lebih mudah menghubungi mereka untuk informasi lebih lanjut jika diperlukan.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'ACDE Knowledgebase sedang diunduh ke perangkat. Mohon maaf atas ketidaknyamanannya. Halaman akan dialihkan secara otomatis saat pemuatan konten selesai.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Mohon maaf atas ketidaknyamanannya. Tautan yang digunakan bukan lokasi yang valid di ACFS',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'Endpoint yang dipilih pada Halaman Tindakan Perbaikan tidak cocok dengan salah satu endpoint active service task yang ditetapkan. Pilih OK untuk melanjutkan. Batalkan untuk mengubah.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Pilih “Ya” jika Anda menyarankan agar Tindakan Perbaikan baru perlu dibuat oleh administrator.',
  'Select this repair action template to specify the action completed':
    'Pilih template tindakan perbaikan ini untuk menentukan tindakan yang telah selesai',
  'Custom Repair Performed': 'Perbaikan kustom Dilakukan',
  'Submit': 'Submit',
  'Needs reviewed for new RA': 'Perlu ditinjau untuk RA baru',
  'Title': 'Titel',
  'Title of repair': 'Titel perbaikan',
  'Description of repair': 'Deskripsi perbaikan',
  'Duration to complete repair (minutes)':
    'Durasi untuk menyelesaikan perbaikan (menit)',
  '3 or more Characters are required for the search.':
    '3 huruf atau lebih dibutuhkan untuk pencarian',
  'Title is required': 'Judul diperlukan',
  'Title should not be more than 200 characters':
    'Judul tidak melebihi dari 200 karakter',
  'Details is required': 'Detail diperlukan',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'Perubahan bahasa dilakukan dalam 2 tahap. Data statis seperti label dan judul akan segera dialihkan. Data konten memerlukan koneksi online dan dapat memakan waktu hingga satu menit atau lebih bergantung kecepatan koneksi Anda untuk melengkapkan pilihan bahasa baru. Hingga konten diunduh, aplikasi akan terus menampilkan bahasa sebelumnya. Item yang baru dilihat dan disimpan akan menjadi yang terakhir diperbarui dan memerlukan waktu hingga satu menit tambahan.',
  'No results Or Multiple Results, please narrow your search':
    'Tidak ada hasil atau hasil ganda, persempit pencarian Anda',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Masukkan nomor seri untuk melihat Insight dan detail peralatan tersebut',
  'Open': 'Buka',
  'Interrupt': 'Interupsi',
  'Incomplete': 'Tidak lengkap',
  'Closed': 'Tutup',
  'Cancelled': 'Dibatalkan',
  'Scanned successfully, Redirecting to the scanned link':
    'Berhasil dipindai, mengalihkan ke tautan yang yang dipindai',
  'Search Serial number': 'Cari Nomor seri',
  'No System is Selected': 'Tidak ada System yang dipilih',
  'Cancelled Tasks': 'Pekerjaan dibatalkan',
  'Allowed': 'Diizinkan',
  'Not Allowed': 'Tidak Diizinkan',
  'Open Task UserId': 'Buka Pekerjaan ID Pengguna',
  'Observations': 'Pengamatan',
  'GroupName or UserID': 'Nama Grup atau ID Pengguna',
  'AI Summary': 'Ringkasan AI',
  'Chronic / Worst Performer Details': 'Rincian Pemain Kronis / Terburuk',
  'Triage Notes': 'Catatan Triase',
  'Search By': 'Cari Berdasarkan',
  'Serial Number': 'Nomor Seri',
  'Tag': 'Menandai',
  'Temporarily add equipment': 'Tambahan peralatan untuk sementara',
  'System': 'Sistem',
  'Machine Name': 'Nama Mesin',
  'No data is available': 'Tidak ada data yang tersedia',
  'Connections': 'Koneksi',
  'Non-Connected': 'Tidak Terhubung',
  'Part Recommendations': 'Rekomendasi Bagian',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Mohon tunggu pembaruan harian.  Halaman ini akan secara otomatis di-refresh ketika selesai.',
  'Install Date': 'Tanggal Pemasangan',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'Pembaruan harian ACDE KB saat ini sedang berlangsung. Navigasi bagian tidak tersedia. Indikator PerbaikanTindakan juga tidak tersedia. Silakan coba lagi ketika konten telah diunduh.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'Tidak ada data Peralatan yang tersedia untuk Endpoint {{endPointId}}.',
};

export default translation;
