const translation = {
  'You switched to {{ language }}': 'Mudou para o {{ language }}',
  'Portuguese': 'Português',
  'Home': 'Início',
  'Search': 'Procura',
  'Saved': 'Salvo',
  'Me': 'Minha página',
  'Menu': 'Menu',
  'English': 'Inglês',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'Definições',
  'Language': 'Idioma',
  'Saved Items': 'Artigos salvos',
  'My tasks': 'Minhas tarefas',
  'Parts': 'Peças',
  'Saved items': 'Artigos salvos',
  'Notifications': 'Notificações',
  'Help & Feedback': 'Ajuda & Feedback',
  'Logout': 'Sair',
  'Add to equipment list': 'Adicionar a lista de equipos',
  'Download for offline': 'Descarregar para offline',
  'Repair Action': 'Acção de reparação',
  'Repair action': 'Acção de reparação',
  'Part': 'Peça',
  'Refreshed {{time}} min ago': 'Actualizado hà {{time}} min',
  'Recently viewed': 'Visto recentemente ',
  'View all': 'Ver tudo',
  'Clear All': 'Apagar tudo',
  'Expand all': 'Ampliar tudo',
  'Collapse all': 'Colapsar tudo',
  'Search results': 'Procura resultados',
  'Repairs': 'Reparações',
  'Downloading content...': 'Descarregar conteudo...',
  'Bookmarks': 'Links favoritos',
  'Downloads': 'Descargas',
  'Recent': 'Recente',
  'Recent codes': 'Codes recentes',
  'Saved codes': 'Codes salvos ',
  'Updates': 'Actualizações',
  'Clear': 'Apagar',
  'Updated Content for {{item}}.': 'Conteúdo Actualizado {{item}}',
  '{{item}} has been saved': ' {{item}} foi salvo ',
  '{{item}} could not be saved': '{{item}} não pôde ser salvo',
  '{{item}} has been removed from saved': '{{item}} foi apagado dos salvados',
  '{{item}} could not be removed from saved':
    '{{item}} não foi apagado dos salvos',
  'Difficulty': 'Dificuldade',
  'Repair time': 'Tempo de reparação',
  'min_one': '1 min',
  'min_other': '{{count}} mins',
  'min_zero': '0 min',
  'Rating': 'Classificação',
  'Thank you for the feedback': 'Obrigado pelo feedback',
  'Not relevant': 'Não relevante',
  'Relevant': 'Relevante',
  'Fixed my issue': 'Resolvi o problema',
  'Leave feedback': 'Deixar feedback',
  'Save item': 'Salvar item',
  'Low': 'Baixo',
  'Medium': 'Médio',
  'High': 'Alto',
  'This was relevant info': 'Esta informação foi relevante',
  'This was not relevant info': 'Esta informação não era relevante',
  'Add to saved items': 'Adicionar aos items guardados',
  'Remove from downloads': 'Apagar de downloads',
  'Search AllConnect Field Support': 'Procura AllConnect Field Support',
  'Filters': 'Filtros',
  'Page not found': 'Página não encontrada',
  'Return home': 'Regresso a início',
  'Clear all bookmarks': 'Apaga todos os Bookmarks',
  'Search all EFC codes': 'Procura todos os EFC codes',
  'Search all parts': 'Procura todas as Peças',
  'Results ({{resultsCount}})': 'Resultados ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} já está salvo',
  'Details': 'Detalhes',
  'Equipment': 'Sistema',
  'Notes': 'Notas',
  'Preventive Insights': 'Visão Preventiva',
  'Service Insights': 'Conhecimentos do serviço',
  'Equipment Information': 'Informação do sistema',
  'Equipment ID': 'ID do sistema',
  'Model': 'Modelo',
  'Terminal ID': 'ID do terminal',
  'Device serial number': 'Número de série do produto',
  'EFC codes': 'EFC-codes',
  'Steps': 'Passos',
  'Module content': 'Conteudo do modulo',
  'Repair action content': 'Conteúdo da acção de reparação',
  'Fixed issue': 'Problema resolvido',
  'Part Number {{partId}} copied to the clipboard':
    'Número da peça {{partId}} copiado para o clipboard',
  'Relationship tree': 'Árvore de relações',
  'Potential substitutions': 'Potenciais substituições',
  'Loading': 'Carregando',
  'Removed from saved searches': 'Apagado de pesquisas salvas',
  'Added to saved searches': 'Adicionado às pesquisas salvas',
  'All bookmarks cleared': ' Todos os bookmarks apagados',
  'No bookmarks to clear': 'Sem bookmarks a apagar',
  'All recent items cleared': 'Todos os artigos recentes apagados',
  'No recent items to clear': 'Não tem artigos recentes para apagar',
  'You have no recently view history.': 'Sem história recente',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'A mudança de língua é feita em 2 fases. Os dados estáticos, tais como etiquetas e cabeçalhos, são imediatamente alterados. Os dados de conteúdo requerem uma ligação à Internet e, dependendo da sua ligação, pode demorar até um minuto ou mais para actualizar a língua escolhida.  Até que o conteúdo seja descarregado, a aplicação continuará a exibir a antiga língua,',
  'Signing you in': 'Iniciando sessão',
  'Signing you out': 'Encerrando sessão',
  'Oops': 'Oops',
  'Switching language to {{ language }}': 'Mudando idioma para {{ language }}',
  'Data has been fetched in': 'Os dados foram obtidos',
  'Failed to fetch data in': 'Os dados não foram obtidos',
  'No results': 'Sem resultados',
  'URL': 'URL',
  'User Name': 'Nome do utilizador',
  'ACFS App': 'ACFS App',
  'ACFS UI': 'ACFS UI',
  'Offline Data': 'Dados offline',
  'Info': 'Informação',
  'Repair': 'Reparação',
  'RepairActionCount 1': '1 Acción de reparación',
  'RepairActionCount {{count}}': '{{count}} Acções de reparação',
  'RepairActionCount 0': '0 Acções de reparação',
  'All': 'Tudo',
  'Sync': 'Sincr',
  'Last 30 days': 'Últimos 30 dias',
  'Not yet implemented': 'Sem implementar',
  'Categories': 'Categorias',
  'Repair actions': 'Acções de reparação',
  'Module': 'Módulo',
  'Preferences': 'Preferências',
  'Service Manuals': 'Manuais de serviço',
  'Service Bulletins': 'Boletim de serviço',
  'FCOs': 'FCOs',
  'FAQs': 'FAQs',
  'Training Content': 'Conteudo de formação',
  'Your feedback has been sent': 'O seu feedback foi enviado',
  'Failed to send your feedback': 'Feedback não foi enviado',
  'Comments are required': 'Comentários requeridos',
  'Comments should not be more than 100 characters':
    'O comentário não deve ter mais de 100 caracteres',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Max {{numOfPhotos}} fotos podem ser carregadas',
  'Add this Task - {{taskNumber}}': 'Adicione esta tarefa - {{taskNumber}}',
  "Other '{{inputValue}}'": "Outro '{{inputValue}}'",
  'Other (Type to add) / Blank': 'Outro (Type to add) / Blank',
  'General feedback': 'Feedback geral',
  'Feedback: EFC code - {{efcCode}}': 'Feedback: EFC code - {{efcCode}}',
  'Module: {{moduleName}}': 'Módulo: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'As sugestões para um EFC-Code, acção de reparação ou uma peça específica devem ser submetidas utilizando a opção de feedback disponível no ecrã correspondente,',
  'Corrective action required': 'Acção correctiva necessária',
  'Yes': 'Sim',
  'No': 'Não',
  'Task number': 'Número de tarefa',
  'Select or Type to add': 'Seleccione ou escreva para adicionar',
  'Comments': 'Comentários',
  'Delete': 'Apagar',
  'Open Camera': 'abrir a câmara',
  'Upto {{maxFiles}} images can be selected':
    'Podem ser seleccionadas até {{maxFiles}} imagens',
  'Cancel': 'Cancelar',
  'Add': 'Adicionar',
  'Add equipment': 'Adicionar sistema',
  'Serial number': 'Número de serie',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Digitalize o código do sistema ou introduza o número de série para ver os conhecimentos e detalhes desse sistema',
  'Last synced: {{time}} min ago': 'Última sincronização: {{time}} min atrás',
  'Send': 'Enviar',
  "Task Number must be provided if 'Corrective action required' is Yes":
    'Fornecer um número de tarefa no caso de ser necessária uma acção correctiva.',
  'Time to fix (minutes)': 'Tempo de reparação (minutes)',
  'Time to fix should not be more than 999':
    'O tempo de reparação não deve exceder 999',
  'Please enter positive numbers only':
    'Por favor introduza só números positivos',
  'Please enter numbers only': 'Por favor introduza só números',
  'Clear filters': 'Apagar filtros',
  'Show results': 'Mostrar resultados',
  'Your feedback has been submitted': 'O seu feedback foi enviado',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'Este EFC está ligado a uma ligação de enriquecimento que está actualmente activa.Por favor, informe-nos se o enriquecimento é relevante (indique a acção apropriada).Se desejar deixar feedback adicionais, por favor seleccione "Deixar feedback".',
  'Products': 'Produtos',
  'Product Number {{productId}} copied to the clipboard':
    'Número do produto {{productId}} copiado para o clipboard',
  'View Groups': 'Mostrar grupos',
  'Search all products': 'Procurar todos os produtos',
  'Current': 'Actual',
  'History': 'Histórico',
  'No equipment data found for serial number {{number}}.':
    'Não foram encontrados dados do sistema para este número de série {{number}}',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Os dados do sistema para este número de série {{number}} não estão disponíveis.',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Dados do sistema não disponíveis. Voltar aos dados offline',
  'Unable to retrieve service task. Falling back to offline data.':
    'Tarefa de serviço não disponível. Voltar aos dados offline',
  'Please wait while data is being loaded in {{language}}':
    'Por favor aguarde enquanto os dados em {{language}} são carregados.',
  'Errors': 'Erros',
  'Tasks': 'Tarefas',
  'History Parts': 'Histórico de peças',
  'Unable to retrieve Service Task history.':
    'Histórico de tarefa não disponível',
  'Recommended Parts': 'Peças recomendadas',
  'Service Task Details': 'Detalhes de tarefa',
  'Task Number': 'Número de tarefa',
  'Task Status': 'Status de tarefa',
  'Problem': 'Problema',
  'Task Type': 'Tipo de tarefa',
  'Customer': 'Cliente',
  'Effective Date': 'Data efectiva',
  'Device Description': 'Descrição do dispositivo',
  'Device Serial Number': 'Número de serie do dispositivo',
  'EndPoint Id': 'EndPoint Id',
  'Service Task Notes {{length}}.': 'Notas de Tarefa de Serviço {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'A Peça {{partId}} não pode ser encontrada nos dados DNSpares',
  'Number': 'Número',
  'Site Number': 'Número do sítio',
  'Address': 'Endereço',
  'Contact Date': 'Data de contacto',
  'Contact Name': 'Nome de contacto',
  'Contact Email': 'Email de contacto',
  'Contact Phone': 'Telefone de contacto',
  'Contract Hours': 'Horas de contrato',
  'Site Messages:': 'Mensagens do sítio:',
  'Type': 'Tipo',
  'Product': 'Produto',
  'Desc': 'Desc',
  'Task Number should not be more than 30 characters':
    'O número de tarefa não deve ter mais de 30 caracteres',
  'Select Photos': 'Selecionar fotos',
  'Session Expired': 'Sessão expirada',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'A sessão atual expirou enquanto você estava offline. Reconecte seu dispositivo e selecione o botão iniciar sessão abaixo para retornar à tela inicial.',
  'Login': 'Iniciar sessão',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Por favor, escreva aqui o seu feedback para melhorar o aplicativo ACFS. Não inclua neste feedback um Código EFC ou ação de reparação específica',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Por favor, escreva aqui o seu feedback para melhorar o conteúdo deste EFC code específico',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'Está faltando algo nesta ação de reparação? Por exemplo, a classificação por estrelas ou o tempo de reparação devem ser ajustados? Envie-nos seus comentários para melhorar o conteúdo desta ação corretiva específica e sua associação com o EFC que a sugere.',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Selecione "Sim" se você recomendar que os administradores façam uma alteração',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'O feedback não é obrigatório. Por favor, se possível, escreva em inglês. As sugestões são usadas para melhorar o conteúdo do ACFS. As informações serão compartilhadas com os especialistas e você poderá ser contatado pelos administradores para fornecer informações adicionais para melhorar o conteúdo ou outras finalidades.',
  'OfflineMessage':
    'Quando o ACFS estiver offline, algum conteúdo não estará disponível devido à natureza do conteúdo, frequência das atualizações e restrições de armazenamento no dispositivo final. Por causa disso...',
  'OfflineMessage1':
    'Nas páginas Início e Me, a seção Minhas tarefas exibirá apenas as tarefas abertas disponíveis.',

  'OfflineMessage2':
    'Nas páginas relacionadas ao sistema (Sistema, Detalhes, Peças), os dados do sistema e a tarefa de serviço serão exibidos com base na disponibilidade do dispositivo.',

  'OfflineMessage3':
    'A página Notas exibirá apenas comentários para tarefas de serviço abertas que estão disponíveis no dispositivo correspondente ao sistema.',

  'OfflineMessage4':
    'A funcionalidade Adicionar sistema nas páginas não funcionará porque o número de série fornecido pelo usuário não pode ser encontrado.',

  'Feedback: Repair # {{repairActionId}}':
    'Feedback": # de Reparação {{repairActionId}}',
  'Training': 'Formação',
  'General Help': 'Ajuda Geral',
  'Offline Disclaimer': 'Offline Disclaimer',
  'Nothing here yet': 'Ainda nada aqui',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Incapaz de recuperar tarefas. Regressando aos dados off-line',
  'Category': 'Categoria',
  'Family': 'Família',
  'Skill level required': 'Nível de qualificação exigido',
  'Sort by': 'Ordenar por',
  'Subsystem': 'Subsistema',
  'Recent records': 'Registos recentes',
  'Systems': 'Sistemas',
  'Task - {{taskNumber}}': 'Tarefa - {{taskNumber}}',
  'Add New': 'Adicionar Novo',
  'Everywhere': 'Em todo o lado',
  'Keywords': 'Keywords',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'A página de detalhes contém conteúdo apenas quando há uma chamada activa atribuída ao utilizador actual para este equipamento',
  'OfflineMessage5':
    'Imagens no conteúdo da aplicação, tais como imagens EFC, peças e imagens das etapas de ações de reparação não serão exibidas se o utilizador estiver offline',
  'Module(EFC only)': 'Módulo (apenas EFC)',
  'German': 'Alemão',
  'Francias': 'Francês',
  'Polish': 'Polaco',
  'Espanol': 'Espanhol',
  'Indonesian': 'Indonésio',
  'Chinese': 'Chinês',
  'Thai': 'Tailandês',
  'Vietnamese': 'Vietnamita',
  'Turkish': 'Turco',
  'If no stock, replaces': 'Se não houver stock, substitui',
  'If no stock, replaced by': 'Se não houver stock, substituído por',
  'Replaces': 'Substitui',
  'Replaced by': 'Substituída por',
  'Repair Id': 'ID de reparação',
  'View Child Ids': 'Ver Child Ids',
  'Device Id': 'ID do produto',
  'Equipment with serial number {{number}} added Succesfully.':
    'Sistema com número de série {{number}} adicionado com êxito.',
  'mfg': 'Número de Fabricação',
  'Reporting System': 'Sistema de relatório',
  'Update': 'atualização',
  'No Endpoint': 'Sem ponto de extremidade',
  'Repair Action performed': 'Ação de reparação executada',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'O conteúdo da base de conhecimento ACDE está a ser descarregado para este dispositivo. Pedimos desculpa pelo incómodo. A página será redirecionada automaticamente quando a transferência do conteúdo estiver concluída.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Pedimos desculpa pelo incómodo. A ligação utilizada não é uma página válida no ACFS.',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'O ponto de extremidade selecionado na Página de Ação de Reparação não corresponde a nenhum dos pontos de extremidade na tarefa ativa atribuída. Selecione OK para continuar. Cancelar para alterar.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Selecione "Sim" se quiser recomendar aos administradores que criem uma Ação de Reparação nova.',
  'Select this repair action template to specify the action completed':
    'Selecione este modelo de ação de Reparação para especificar a ação executada.',
  'Custom Repair Performed': 'Especificar a reparação executada',
  'Submit': 'Enviar',
  'Needs reviewed for new RA': 'Necessidades reconhecidas para a nova AR',
  'Title': 'Título',
  'Title of repair': 'Título da reparação',
  'Description of repair': 'Descrição da reparação',
  'Duration to complete repair (minutes)':
    'Duração para concluir a reparação (minutos)',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'A substituição de idioma é feita em 2 fases. Os dados estáticos, como rótulos e cabeçalhos, serão alterados imediatamente. Os dados de conteúdo requerem uma conexão on-line e podem levar até um minuto ou mais, dependendo da sua conexão. Até que o conteúdo seja descarregado, o aplicativo continuará a exibir o conteúdo no idioma anterior. Os elementos guardados e visualizados recentemente serão os últimos a serem atualizados e poderão demorar adicionalmente um minuto ou mais.',
  'No results Or Multiple Results, please narrow your search':
    'Nenhum resultado Ou muitos resultados, por favor, restrinja a sua pesquisa.',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Introduza o número de série para ver a visão e os detalhes desta peça do equipamento.',
  'Open': 'Abrir',
  'Interrupt': 'Interromper',
  'Incomplete': 'Incompleto',
  'Closed': 'Fechado',
  'Cancelled': 'Cancelado',
  'Scanned successfully, Redirecting to the scanned link':
    'Digitalização bem sucedida, redireccionamento para a ligação digitalizada.',
  'Search Serial number': 'Pesquisa Número de série',
  'No System is Selected': 'Nenhum sistema está selecionado',
  'Cancelled Tasks': 'Tarefas canceladas',
  'Allowed': 'Permitido',
  'Not Allowed': 'Não permitido',
  'Open Task UserId': 'Tarefa aberta UserId',
  'Observations': 'Observações',
  'GroupName or UserID': 'Nome do grupo ou ID do utilizador',
  'AI Summary': 'Resumo da IA',
  'Chronic / Worst Performer Details':
    'Detalhes do desempenho crónico / pior desempenho',
  'Triage Notes': 'Notas de triagem',
  'Search By': 'Pesquisar por',
  'Serial Number': 'Número de série',
  'Tag': 'Marcação',
  'Temporarily add equipment': 'Adicionar temporariamente equipamento',
  'System': 'Sistema',
  'Machine Name': 'Nome da máquina',
  'No data is available': 'Não existem dados disponíveis',
  'Connections': 'Ligações',
  'Non-Connected': 'Não ligado',
  'Part Recommendations': 'Parte Recomendações',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Aguarde a atualização diária.  A página será actualizada automaticamente quando estiver concluída.',
  'Install Date': 'Data de instalação',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'A atualização diária da ACDE KB está atualmente em curso. A navegação de peças não está disponível. O indicador RepairAction também não está disponível. Por favor, tente novamente quando o conteúdo tiver sido descarregado.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'Não existem dados de Equipamento disponíveis para o Ponto Final {{endPointId}}.',
};

export default translation;
