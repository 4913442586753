import React from 'react';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import Icon from '../../../components/Icon';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import useNavigateToPart from '../../../hooks/useNavigateToPart';
import RecommendedPart from '../../../Models/RecommendedPart';
import { useAppDispatch } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { searchActions } from '../../../features/search/searchSlice';
import { useLiveQuery } from 'dexie-react-hooks';
import { IPart } from '../../../Models/Part';
import db from '../../../index-db';

interface Props {
  recommendedPart: RecommendedPart;
}

function RecommendPart({ recommendedPart }: Props) {
  const navigateToPart = useNavigateToPart();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    navigateToPart(recommendedPart.partNumber);
  };

  const part = useLiveQuery(async () => {
    return recommendedPart.partNumber
      ? await db.parts
          .where('ManufactureNumber')
          .equals(recommendedPart.partNumber)
          .or('MaterialId')
          .equals(recommendedPart.partNumber)
          .and((part: IPart) => part.IsDsaPartMissing === false)
          .first()
      : undefined;
  }, [recommendedPart.partNumber]);

  const onIconClick =
    (searchTerm: string) =>
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      dispatch(searchActions.changeSearchTerm(searchTerm));
      dispatch(searchActions.setScope('repairs'));
      navigate('/search/results', {
        state: { selectedTab: 'repairs', searchTerm },
      });
    };

  return (
    <button className="" type="button">
      <div className="my-task-card mb-2">
        <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-cobalt">
          <Icon
            is={ConstructionOutlinedIcon}
            size="1rem"
            colorClass="text-white"
          />
        </div>
        <div className="w-full min-w-0 rounded-r-lg border-2 border-antartica bg-white p-2">
          <div className="flex items-center justify-between text-left">
            <div className="min-w-0 flex-1" onClick={handleClick}>
              <div className="overflow-ellipsis text-lg font-bold">
                {recommendedPart.partNumber}
              </div>
              <div className="overflow-ellipsis">
                {recommendedPart.partDesc}
              </div>
            </div>
            <div className="flex cursor-pointer gap-2">
              {part && part.HasRepairActions ? (
                <div onClick={onIconClick(recommendedPart.partNumber)}>
                  <Icon is={BuildCircleOutlinedIcon} />
                </div>
              ) : (
                <></>
              )}
              <div onClick={handleClick}>
                <Icon is={ChevronRightOutlinedIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}
export default RecommendPart;
