import * as uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { PropsWithChildren, useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { Button, FormControlLabel, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LogType } from '../../enums/LogType';
import TimelineEvent from '../../Models/TimeLineEvent';
import { TimeLineEventType } from '../../Models/Enumerations';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
  FormProvider,
} from 'react-hook-form';
import db from '../../index-db';
import { MultipleFileSelect } from './MultipleFileSelect';
import Feedback from '../../Models/Feedback';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { useToast } from '../../components/toast/ToastProvider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import useCheckDeviceType from '../../hooks/useCheckDeviceType';
import Task from '../../Models/Task';
import EndPoint from '../../Models/EndPoint';
import { LAST_ACCESSED_ENDPOINT } from '../../constants';

type FeedbackType = 'general' | 'efc' | 'repairAction' | 'part';

interface RequiredProps {
  feedbackType: FeedbackType;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

interface OptionalProps {
  className?: string;
  efcId?: number;
  moduleId?: number;
  efcCode?: string;
  endpointId: string;
  serialNumber: string;
}

type Props = RequiredProps & OptionalProps;

export interface IFormInput {
  needsReview: boolean;
  title: string;
  details: string;
  photos: File[];
  timeToFix: number;
}
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  'width': 42,
  'height': 26,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '300ms',
    '&.Mui-checked': {
      'transform': 'translateX(16px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0769B7',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#7d878e33' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
function CustomRepairActionScreen(props: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const { pushToast } = useToast();
  const logging = useLogging();
  const api = useApi();
  const [selectedEndpoint, setSelectedEndpoint] = useState({} as EndPoint);

  const deviceType = useCheckDeviceType();
  const methods = useForm<IFormInput>({
    mode: 'all',
    defaultValues: {
      needsReview: false,
      title: '',
      details: '',
      photos: [],
    },
  });
  function blobToArrayBuffer(blob: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(blob);
    });
  }

  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';

  const fetchOpenServiceTasks = async (): Promise<Task[]> => {
    return (await api.fetchJson(
      '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
    )) as Task[];
  };

  const fetchEndPoint = async (endpointId: string): Promise<EndPoint> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointById/?endPointId=${endpointId}`
    )) as EndPoint;
  };

  const getDefaultServiceTask = async () => {
    const tasks = await fetchOpenServiceTasks();

    const filteredTasksOnsite = tasks.find(
      (task) =>
        task.sharedStatus === 'On-site' &&
        task.endpointId === lastAccessedEndpoint
    );

    const filteredTasksTravel = tasks.find(
      (task) =>
        task.sharedStatus === 'Travel' &&
        task.endpointId === lastAccessedEndpoint
    );

    const filteredTasksCommitted = tasks.find(
      (task) =>
        task.sharedStatus === 'Committed' &&
        task.endpointId === lastAccessedEndpoint
    );

    return (
      filteredTasksOnsite?.taskNumber ||
      filteredTasksTravel?.taskNumber ||
      filteredTasksCommitted?.taskNumber ||
      (lastAccessedEndpoint !== '' ? selectedEndpoint.serialNumber : '')
    );
  };

  async function SaveTimeLineEvent(timelineEvent: TimelineEvent) {
    try {
      await api.postJson(
        '/dwar/api/almanac/TimelineEvent/addTimelineEvent',
        JSON.stringify(timelineEvent)
      );
    } catch (error) {
      logging('error', 'Unable to add RA timeline event.', error, false, null);
    }
  }

  function CreateTimelineEvent(
    details: string,
    title: string,
    needsReview: boolean,
    timeToFix: number
  ) {
    const currentTime = new Date().toISOString();
    const timelineEventProperties: Record<string, string> = {
      efcId: props.efcId?.toString() ?? '',
      moduleId: props.moduleId?.toString() ?? '',
      details: details,
      needsReview: needsReview.toString(),
      timeToFix: timeToFix.toString(),
    };
    const timelineEvent = new TimelineEvent(
      uuid.v4().toString(),
      title,
      lastAccessedEndpoint,
      'Custom',
      currentTime,
      TimeLineEventType[TimeLineEventType.Repair],
      timelineEventProperties
    );

    return timelineEvent;
  }

  function postFeedback(
    feedback: Feedback,
    timelineEvent: TimelineEvent,
    isiOS: boolean
  ) {
    let logs = feedback;
    if (feedback.taskActivityNumber === selectedEndpoint.serialNumber) {
      logs = { ...feedback, taskActivityNumber: '' };
    }

    const result = api.postFormData(
      '/dwar/api/feedback/FeedBack/saveFeedback',
      feedback,
      isiOS
    );
    methods.reset();
    props.handleSubmit();
    result.then((res) => {
      if (res === true) {
        if (
          lastAccessedEndpoint !== t('No Endpoint') &&
          lastAccessedEndpoint !== ''
        ) {
          SaveTimeLineEvent(timelineEvent);
        }

        let logParams = logFeedBack(props, logs, timelineEvent);

        logging('info', '', '', false, logParams, true);
        pushToast({
          message: t(`Your feedback has been sent`),
          type: 'success',
        });
      } else {
        pushToast({
          message: t(`Failed to send your feedback`),
          type: 'error',
        });
      }
    });
    result.catch((res) => {
      pushToast({
        message: t(
          'Failed to sent feedback, will retry when internet is restored'
        ),
        type: 'error',
      });
    });
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const taskNumber = await getDefaultServiceTask();
    let isiOS = false;
    const photosBuffer: any[] = [];
    if (deviceType === 'iOS') {
      isiOS = true;
      await Promise.resolve(
        Promise.all(
          data.photos.map(async (f: File) => {
            const arrayBuffer = await blobToArrayBuffer(f);
            photosBuffer.push(arrayBuffer);
          })
        )
      );
    }

    const comments = `<b>Custom Repair Action</b><br /><b>Title:</b> ${data.title} <br /><b>Details:</b> ${data.details}	<br /><b>NeedsReview:</b> ${data.needsReview}	<br /><b>Timetofix:</b> ${data.timeToFix}`;

    const feedback: Feedback = {
      raId: 0,
      odysseyModuleId: props.moduleId ?? 0,
      efcCodeId: props.efcId ?? 0,
      recommendRating: 0,
      timeToFix: data.timeToFix ?? 0,
      comments: comments ?? '',
      ratingAccepted: 0,
      timeAccepted: 0,
      taskActivityNumber: taskNumber ?? '',
      correctiveActionRequired: false,
      imageData: data.photos,
      imageBuffer: photosBuffer,
      partNumber: '',
      partName: '',
      endpointId: props.endpointId ?? '',
      serialNumber: props.serialNumber ?? '',
    };

    const timelineEvent = CreateTimelineEvent(
      data.details,
      data.title,
      data.needsReview,
      data.timeToFix
    );
    postFeedback(feedback, timelineEvent, isiOS);
  };

  const handleError: SubmitErrorHandler<IFormInput> = (errors) => {
    logging('error', `error while submitting feedback`, errors, false, null);
  };

  useEffect(() => {
    (async () => {
      if (lastAccessedEndpoint !== '') {
        try {
          const endpoint = await fetchEndPoint(lastAccessedEndpoint);
          setSelectedEndpoint(endpoint);
        } catch (error) {
          const endpoint = await db.endPoints.get(lastAccessedEndpoint);
          if (endpoint) {
            setSelectedEndpoint(endpoint);
          }
        }
      }
    })();
  }, [lastAccessedEndpoint]);

  return (
    <>
      <Sheet
        isOpen={props.isOpen}
        onClose={props.handleClose}
        snapPoints={[620, 450, 60, 0]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, handleError)}>
                <div className="align-center ml-4 flex max-h-max flex-col overflow-y-auto">
                  <div className="-mt-2 flex justify-between text-lg">
                    <Button
                      onClick={() => {
                        methods.reset();
                        props.handleClose();
                      }}
                      style={{ textTransform: 'none' }}
                      color="primary"
                      size="large"
                      sx={{ fontWeight: 700 }}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      color="primary"
                      disabled={!methods.formState.isValid}
                      type="submit"
                      size="large"
                      sx={{ fontWeight: 700 }}
                      style={{ textTransform: 'none' }}
                    >
                      {t('Submit')}
                    </Button>
                  </div>
                  <div className="text-lg font-semibold">
                    {t('Equipment')}: {selectedEndpoint.serialNumber}
                    {' - '}
                    {selectedEndpoint.terminalId}
                  </div>
                  <div className="mt-4 flex items-center">
                    <div className="break-word w-56 text-lg font-semibold">
                      {t('Needs reviewed for new RA')}?
                    </div>
                    <Controller
                      control={methods.control}
                      name="needsReview"
                      defaultValue={false}
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <FormControlLabel
                            label={value ? t('Yes') : t('No')}
                            labelPlacement="start"
                            name="needsReview"
                            control={
                              <IOSSwitch
                                onChange={onChange}
                                inputRef={ref}
                                className="ml-2"
                              />
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="mt-1 mr-1 text-base leading-5 text-steel">
                    {t(
                      'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.'
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">{t('Title')}</div>
                    <TextField
                      id="outlined-basic"
                      multiline
                      size="medium"
                      placeholder=""
                      maxRows={2}
                      variant="outlined"
                      fullWidth
                      sx={{ paddingRight: '30px' }}
                      {...methods.register('title', {
                        required: 'Title is required',
                        maxLength: {
                          value: 200,
                          message: t(
                            'Title should not be more than 200 characters'
                          ),
                        },
                        setValueAs: (v) => v.trim(),
                      })}
                      name="title"
                    />
                    {methods.formState.errors?.title && (
                      <div className="text-tangerine">
                        {methods.formState.errors.title.message}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">{t('Details')}</div>
                    <TextField
                      id="outlined-basic"
                      multiline
                      size="medium"
                      placeholder=""
                      minRows={2}
                      variant="outlined"
                      fullWidth
                      sx={{ paddingRight: '30px' }}
                      {...methods.register('details', {
                        required: 'Details is required',
                        setValueAs: (v) => v.trim(),
                      })}
                      name="details"
                    />
                    {methods.formState.errors?.details && (
                      <div className="text-tangerine">
                        {methods.formState.errors.details.message}
                      </div>
                    )}
                  </div>

                  <div className="mt-4 flex">
                    <div className="mt-2 mr-4 font-medium">
                      {t('Time to fix (minutes)')}
                    </div>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      type="number"
                      sx={{ width: 80 }}
                      {...methods.register('timeToFix', {
                        maxLength: {
                          value: 3,
                          message: t('Time to fix should not be more than 999'),
                        },
                        min: {
                          value: 0,
                          message: t('Please enter positive numbers only'),
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('Please enter numbers only'),
                        },
                      })}
                      name="timeToFix"
                    />
                  </div>
                  {methods.formState.errors?.timeToFix && (
                    <div className="text-tangerine">
                      {methods.formState.errors.timeToFix.message}
                    </div>
                  )}

                  <MultipleFileSelect name="photos" maxFiles={5} />
                </div>
              </form>
            </FormProvider>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default CustomRepairActionScreen;
function logFeedBack(
  props: PropsWithChildren<Props>,
  feedback: Feedback,
  timelineEvent: TimelineEvent
) {
  const logParams: LogParams[] = [
    {
      key: LogType.Page,
      value: LogType.Fb,
    },
    {
      key: LogType.FbType,
      value: props.feedbackType,
    },
    {
      key: LogType.Title,
      value: timelineEvent.description,
    },
    {
      key: LogType.Details,
      value: timelineEvent.properties['details'],
    },
    {
      key: LogType.EfcCode,
      value: props.efcCode ?? '',
    },
    {
      key: LogType.EndpointId,
      value: feedback.endpointId,
    },
    {
      key: LogType.SerialNumber,
      value: feedback.serialNumber,
    },
    {
      key: LogType.ServiceId,
      value: feedback?.taskActivityNumber ?? '',
    },
    {
      key: LogType.NeedsReview,
      value: timelineEvent.properties['needsReview'],
    },
    {
      key: LogType.TimeToFix,
      value: timelineEvent.properties['timeToFix'],
    },
  ];
  return logParams;
}
