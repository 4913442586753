import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import NorthWestOutlined from '@mui/icons-material/NorthWestOutlined';
import LaunchOutlined from '@mui/icons-material/LaunchOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import classnames from 'classnames';
import React from 'react';
import { TIcon } from '../types';
import Icon from './Icon';
import IconButton from './IconButton';
import { ChildLevel } from '../Models/Enumerations';
import {
  RadioButtonInterface,
  InputTextBoxInterface,
} from '../Models/ReusableComponents';
import CustomRadioGroup from './RadioGroup';
import Flame from './icons/Flame';
import InputTextBox from './InputTextBox';

type PrimaryAction =
  | 'navigate'
  | 'applySearchTerm'
  | 'select'
  | 'launch'
  | 'copy';

type AppearanceFlag =
  | 'setting'
  | 'nonBold'
  | 'cobaltTrailingIcon'
  | 'colorInverted';

interface RequiredProps {
  title: string;
  primaryAction: PrimaryAction; // The trailing icon is based on this property
}

interface OptionalProps {
  highlightedTitlePart?: string;
  icon?: TIcon; // The leading icon
  image?: string;
  imageHeight?: string;
  imageWidth?: string;
  intermediateIcon?: TIcon;
  onClickIntermediateIcon?: (e: React.MouseEvent) => void;
  subText?: string;
  secondarySubText?: string;
  subTitle?: string;
  subTextPosition?: 'below title' | 'right of title';
  hidePrimaryActionIcon?: boolean;
  appearance?: AppearanceFlag | AppearanceFlag[];
  onClickIcon?: () => void; // If given, turns the leading icon into a button
  childLevel?: number;
  enhancedCopying?: boolean;
  radioButtons?: RadioButtonInterface;
  inputTextbox?: InputTextBoxInterface;
  onClick?: () => void; // The primary click action, aka the entire row except for the icon on the left IF it's an action icon
  isChronic?: boolean;
  onContentClick?: (e: React.SyntheticEvent) => void;
}

type Props = RequiredProps & OptionalProps;

const defaultProps: OptionalProps = {
  highlightedTitlePart: undefined,
  appearance: [],
  subTextPosition: 'below title',
  hidePrimaryActionIcon: false,
  childLevel: 0,
  onClickIntermediateIcon: () => {},
  isChronic: false,
};

function ActionableListItem(props: Props) {
  const trailingIconMap: Record<PrimaryAction, TIcon> = {
    navigate: ChevronRightOutlinedIcon,
    applySearchTerm: NorthWestOutlined,
    select: DoneOutlinedIcon,
    launch: LaunchOutlined,
    copy: CopyAllOutlinedIcon,
  };

  const hasAppearance = (flag: AppearanceFlag): boolean => {
    return (
      props.appearance === flag || (props.appearance?.includes(flag) ?? false)
    );
  };

  const renderTitle = () => {
    if (!props.highlightedTitlePart) {
      return props.title;
    }

    const index = props.title
      .toLowerCase()
      .indexOf(props.highlightedTitlePart.toLowerCase());

    if (index === -1) {
      // Nothing to highlight, substring not found
      return props.title;
    }

    return (
      <>
        {props.title.substring(0, index)}
        <span className="text-cobalt">
          {props.title.substr(index, props.highlightedTitlePart.length)}
        </span>
        {props.title.substring(index + props.highlightedTitlePart.length)}
      </>
    );
  };

  return (
    <div
      className={classnames('flex min-h-[56px]', {
        'border-b border-antartica': !hasAppearance('setting'),
        'mb-px': hasAppearance('setting'),
        'ml-6': props.childLevel === ChildLevel.FirstGen,
        'ml-12': props.childLevel === ChildLevel.SecondGen,
        'bg-dnblue': hasAppearance('colorInverted'),
        'bg-white': !hasAppearance('colorInverted'),
      })}
    >
      {props.icon && props.onClickIcon && (
        <IconButton
          className="ml-1.5 -mr-3.5 self-center"
          iconColorClass={hasAppearance('colorInverted') ? 'text-white' : ''}
          icon={props.icon}
          onClick={props.onClickIcon}
        />
      )}

      <div
        className={`flex min-w-0 flex-1 items-center gap-x-3 self-stretch px-4 text-left ${
          !props.enhancedCopying ? 'cursor-pointer' : ''
        } `}
        {...(!props.enhancedCopying && { onClick: props.onClick })}
      >
        {props.icon && !props.onClickIcon && <Icon is={props.icon} />}

        <div
          className={classnames('min-w-0 flex-1', {
            'flex flex-row items-baseline justify-between':
              props.subTextPosition === 'right of title',
            'text-white': hasAppearance('colorInverted'),
          })}
          onClick={props?.onContentClick}
        >
          <p
            className={classnames('overflow-ellipsis text-lg', {
              'font-bold': !hasAppearance('nonBold'),
              'font-normal': hasAppearance('nonBold'),
            })}
          >
            {renderTitle()}

            {props.subTitle && (
              <span
                className={classnames('pl-3 sm:text-sm', {
                  'text-steel': !hasAppearance('colorInverted'),
                  'text-white': hasAppearance('colorInverted'),
                })}
              >
                {props.subTitle}
              </span>
            )}
          </p>
          {!!props.subText && (
            <p
              className={classnames('overflow-ellipsis', {
                'text-steel': !hasAppearance('colorInverted'),
                'text-white': hasAppearance('colorInverted'),
              })}
            >
              {props.subText}
            </p>
          )}
          {props?.secondarySubText && (
            <p
              className={classnames(' break-words font-bold', {
                'text-steel': !hasAppearance('colorInverted'),
                'text-white': hasAppearance('colorInverted'),
              })}
            >
              {props.secondarySubText}
            </p>
          )}
        </div>
        {props?.isChronic && <Flame />}
        {props?.intermediateIcon && props?.onClickIntermediateIcon && (
          <IconButton
            className="ml-1.5 -mr-3.5 self-center"
            iconColorClass={hasAppearance('colorInverted') ? 'text-white' : ''}
            icon={props.intermediateIcon}
            onClick={props.onClickIntermediateIcon}
          />
        )}
        {props.image && (
          <img
            src={props.image}
            width={props.imageWidth ?? 30}
            height={props.imageHeight ?? 20}
            alt=""
          />
        )}
        {props?.radioButtons && <CustomRadioGroup {...props.radioButtons} />}
        {props?.inputTextbox && <InputTextBox {...props.inputTextbox} />}
        {!props.hidePrimaryActionIcon && (
          <IconButton
            icon={trailingIconMap[props.primaryAction]}
            iconColorClass={classnames({
              'text-cobalt': hasAppearance('cobaltTrailingIcon'),
              'text-white': hasAppearance('colorInverted'),
            })}
            onClick={
              props.enhancedCopying && props.onClick ? props.onClick : () => {}
            }
          />
        )}
      </div>
    </div>
  );
}

ActionableListItem.defaultProps = defaultProps;

export default ActionableListItem;
