import Dexie, { PromiseExtended, Table } from 'dexie';
import EFC from './Models/EFC';
import Family from './Models/Family';
import Model from './Models/Model';
import Module from './Models/Module';
import Part from './Models/Part';
import RecentlyViewed from './Models/RecentlyViewed';
import RepairAction from './Models/RepairAction';
import SavedItem from './Models/SavedItem';
import SearchedItems from './Models/SearchedItems';
import SubSystem from './Models/SubSystem';
import Task from './Models/Task';
import EndPoint from './Models/EndPoint';
import ManualEndPoint from './Models/ManualEndPoint';
import Product from './Models/Product';
import FailedFeedback from './Models/FailedFeedback';
import Notification from './Models/Notifications';
import AudioAiSummary from './Models/AudioAiSummary';
import CallToActionSettings from './Models/CallToActionSettings';
import FailedCallToActionSettings from './Models/FailedCallToActionSettings';

export const DATABASE_NAME = 'DNSM';
export const DATABASE_INIT_TIMESTAMP_KEY = 'acde:last-init-of-indexed-db';

export class DNSMLocalStore extends Dexie {
  efcs!: Table<EFC>;
  raactions!: Table<RepairAction>;
  modules!: Table<Module>;
  familys!: Table<Family>;
  parts!: Table<Part>;
  products!: Table<Product>;
  models!: Table<Model>;
  subsystems!: Table<SubSystem>;
  tasks!: Table<Task>;
  recentlyViewed!: Table<RecentlyViewed>;
  searchedItems!: Table<SearchedItems>; // TODO these are saved in local storage, remove
  savedItems!: Table<SavedItem>;
  endPoints!: Table<EndPoint>;
  manualEndPoints!: Table<ManualEndPoint>;
  failedFeedback!: Table<FailedFeedback>;
  notifications!: Table<Notification>;
  audioAiSummary!: Table<AudioAiSummary>;
  callToActionSettings!: Table<CallToActionSettings>;
  failedCallToActionSettings!: Table<FailedCallToActionSettings>;

  constructor() {
    super(DATABASE_NAME);
    this.version(27).stores({
      efcs: 'Id, EfcCodeStr, ProbableCause,LastUpdate,ModuleId', // Primary key and indexed props
      raactions: 'Id, Name, Description,*PartNumbers,ModuleId',
      modules: 'Id, ModuleIdentifier, ModuleName, Abbreviation',
      familys: 'Id, FamilyName',
      parts: 'MaterialId,ManufactureNumber',
      products: 'ProductId,Description',
      subsystems: 'Id, SubSystemName',
      models: 'Id, ModelName',
      tasks: 'id, taskNumber,endpointId',
      recentlyViewed: '++id,[IdGeneric+IdType],IdType',
      searchedItems: 'Id',
      savedItems: '++id,[IdGeneric+IdType],IdType',
      endPoints: 'id',
      manualEndPoints: 'id,externallyNavigated',
      failedFeedback: '++id',
      audioAiSummary: 'key,value,lastLoadTime',
      notifications:
        'id, text,description, creationtime,releasetime,languageCode,isRead,userType,notificationType,lastActionTaken',
      callToActionSettings: 'id,endpointId,taskNumber',
      failedCallToActionSettings: '++id',
    });
    this.efcs.mapToClass(EFC);
    this.raactions.mapToClass(RepairAction);
    this.parts.mapToClass(Part);
    this.products.mapToClass(Product);
    this.recentlyViewed.mapToClass(RecentlyViewed);
    this.searchedItems.mapToClass(SearchedItems);
    this.modules.mapToClass(Module);
    this.familys.mapToClass(Family);
    this.subsystems.mapToClass(SubSystem);
    this.models.mapToClass(Model);
    this.tasks.mapToClass(Task);
    this.savedItems.mapToClass(SavedItem);
    this.endPoints.mapToClass(EndPoint);
    this.manualEndPoints.mapToClass(ManualEndPoint);
    this.failedFeedback.mapToClass(FailedFeedback);
    this.notifications.mapToClass(Notification);
    this.audioAiSummary.mapToClass(AudioAiSummary);
    this.callToActionSettings.mapToClass(CallToActionSettings);
    this.failedCallToActionSettings.mapToClass(FailedCallToActionSettings);
  }

  /**
   * load
   */
  public load(data: Map<string, any>): Promise<void> {
    const promises = [];
    const dataKeysArray = Array.from(data.keys());
    for (let i = 0; i < dataKeysArray.length; i++) {
      const key = dataKeysArray[i];
      switch (key) {
        case 'efcCode.json':
          promises.push(this.populateDB(db.efcs, data.get(key) as EFC[]));
          break;
        case 'repairAction.json':
          promises.push(
            this.populateDB(db.raactions, data.get(key) as RepairAction[])
          );
          break;
        case 'Parts.json':
          promises.push(this.populateDB(db.parts, data.get(key) as Part[]));
          break;
        case 'Products.json':
          promises.push(
            this.populateDB(db.products, data.get(key) as Product[])
          );
          break;
        case 'model.json':
          promises.push(this.populateDB(db.models, data.get(key) as Model[]));
          break;
        case 'module.json':
          promises.push(this.populateDB(db.modules, data.get(key) as Module[]));
          break;
        case 'subSystem.json':
          promises.push(
            this.populateDB(db.subsystems, data.get(key) as SubSystem[])
          );
          break;
        case 'family.json':
          promises.push(this.populateDB(db.familys, data.get(key) as Family[]));
          break;
      }
    }
    Promise.allSettled =
      Promise.allSettled ||
      ((promises: any[]) =>
        Promise.all(
          promises.map((p: Promise<any>) =>
            p
              .then((value: any) => ({
                status: 'fulfilled',
                value,
              }))
              .catch((reason: any) => ({
                status: 'rejected',
                reason,
              }))
          )
        ));
    return Promise.allSettled(promises).then((results) =>
      results.forEach((result) => console.log(result.status))
    );
  }

  private populateDB(table: Table, content: any): PromiseExtended<void> {
    return db
      .transaction('rw', table, async () => {
        const keysToDelete = await table
          .filter((x: any) => !content.find((f: any) => f?.Id === x?.Id))
          .primaryKeys();
        if (keysToDelete?.length) {
          await table.bulkDelete(keysToDelete);
        }
        await table.bulkPut(content);
      })
      .then(() => {
        console.log(`Successfully added data for table ${table.name}`);
      })
      .catch((err) => {
        console.error(`Error in adding data to table ${table.name}`);
      });
  }
}

const db = new DNSMLocalStore();

db.on('versionchange', function (event) {
  console.log('Version changed indexdb');
  window.location.reload();
});

export default db;
