import CopyAllOutlined from '@mui/icons-material/ContentCopyOutlined';
import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import { useToast } from '../../components/toast/ToastProvider';
import useClipBoardCopy from '../../hooks/useClipBoardCopy';
import useNavigateToPart from '../../hooks/useNavigateToPart';
import Part from '../../Models/Part';
import TimelineEvent from '../../Models/TimeLineEvent';
import RecommendedPart from '../../Models/RecommendedPart';

interface Props {
  part: Part;
  recommendedParts: RecommendedPart[];
  partTimelineEvents: TimelineEvent[];
}

function RepairActionPart(props: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const navigateToPart = useNavigateToPart();
  const clipboardCopy = useClipBoardCopy();
  const handleClick = (idGen: string) => {
    navigateToPart(idGen);
  };

  let displayTime: string = '';

  const recommendedPart = props.recommendedParts.find(
    (recPart) => recPart.partNumber === props.part.MaterialId
  );
  const timelineEvent = props.partTimelineEvents.find(
    (timelineEvent) => timelineEvent.event === props.part.MaterialId
  );

  if (timelineEvent) {
    const currentDate = new Date(Date.now());
    const timestamp = new Date(timelineEvent.timestamp);

    const diffInTime = currentDate.getTime() - timestamp.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    const hours =
      timestamp.getHours() < 10
        ? `0${timestamp.getHours()}`
        : timestamp.getHours().toString();
    const minutes =
      timestamp.getMinutes() < 10
        ? `0${timestamp.getMinutes()}`
        : timestamp.getMinutes().toString();
    const seconds =
      timestamp.getSeconds() < 10
        ? `0${timestamp.getSeconds()}`
        : timestamp.getSeconds().toString();
    displayTime = `${diffInDays} days ago ${hours}:${minutes}:${seconds} ${timestamp.toDateString()}`;
  }

  return (
    <ActionableListItem
      key={props.part.MaterialId}
      icon={CopyAllOutlined}
      title={props.part.MaterialId}
      subText={props.part.PartName}
      secondarySubText={displayTime}
      subTitle={
        props.part.ManufactureNumber &&
        `(${t('mfg')}: ${props.part.ManufactureNumber})`
      }
      image={recommendedPart ? './acde-recommended.png' : ''}
      imageHeight="25"
      imageWidth="35"
      primaryAction="navigate"
      onClick={() => {
        handleClick(props.part.MaterialId);
      }}
      enhancedCopying
      onClickIcon={() => {
        clipboardCopy(props.part.MaterialId);
        toast.pushToast({
          message: t(`Part Number {{partId}} copied to the clipboard`, {
            partId: props.part.MaterialId,
          }),
        });
      }}
    />
  );
}

export default RepairActionPart;
