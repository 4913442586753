export enum IDType {
  EFC = 1,
  REPAIR_ACTION,
  PARTS,
  PRODUCTS,
  SEARCH,
}

export enum Filters {
  CATEGORY = 1,
  MODULE,
  MODEL,
  FAMILY,
  SUBSYSTEM,
  SKILL_LEVEL_REQUIRED,
  SORT_BY,
}

export enum InsightType {
  Service,
  Preventive,
}

export enum ChildLevel {
  CurrentGen,
  FirstGen,
  SecondGen,
}

export enum TimeLineEventType {
  All,
  Task,
  EFC,
  Part,
  Repair,
  ServiceInsight,
}

export enum NotificationType {
  System = 'System',
  App = 'App',
  Silent = 'Silent',
}
export enum userType {
  All = 'All',
  DN = 'DN',
  Partners = 'Partners',
}

export enum CallToActionType {
  Part = 1,
  AiSummary,
  PreventiveInsight,
  ServiceInsight,
  Observation,
}
