import { useTranslation } from 'react-i18next';
import { wrap } from 'comlink';

import EFCCard from '../../../components/EFCCard';
import useNavigateToEfc from '../../../hooks/useNavigateToEfc';

import EFC from '../../../Models/EFC';

import LazyLoader from '../../../components/utility/LazyLoader';
import useSearchGetResults from '../../../hooks/useSearchGetResults';
import { EfcSearchWorkerType } from '../../../worker/efcSearch/searchWorker';
import {
  SHOULD_SINGLE_SEARCH_BE_PERFORMED,
  lazyLoadLimit,
} from '../../../constants';
import { EfcSearchCountWorkerType } from '../../../worker/efcSearch/searchCountWorker';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';

interface Props {
  handleEFCResultsLoaded: (count: number) => void;
  renderEfcResults: boolean;
  totalResultsCount: number;
  areAllResultsLoaded: boolean;
}
const fetcher = async (
  regex: RegExp,
  offset: number,

  selectedFilter?: string
) => {
  const worker = new Worker(
    new URL('../../../worker/efcSearch/searchWorker.ts', import.meta.url),
    { name: 'efcWorker' }
  );

  const workerApi = wrap<EfcSearchWorkerType>(worker);

  try {
    const results = await workerApi.search({
      moduleFilter: selectedFilter,
      totalRes: false,
      offset: offset,
      regex,
      limit: lazyLoadLimit,
    });

    worker.terminate();
    return results;
  } catch (e) {
    worker.terminate();
    return [];
  }
};
const countFetcher = async (regex: RegExp, selectedFilter?: string) => {
  const worker = new Worker(
    new URL('../../../worker/efcSearch/searchCountWorker.ts', import.meta.url),
    { name: 'efcCountWorker' }
  );
  const workerApi = wrap<EfcSearchCountWorkerType>(worker);

  try {
    const resultCount = await workerApi.searchCount({
      moduleFilter: selectedFilter,
      totalRes: true,
      offset: null,
      regex,
      limit: undefined,
    });
    worker.terminate();
    if (typeof resultCount === 'number') {
      return resultCount;
    }
    return 0;
  } catch (e) {
    worker.terminate();
    return 0;
  }
};

function EFCResults(props: Props) {
  const {
    handleEFCResultsLoaded,
    renderEfcResults,
    totalResultsCount,
    areAllResultsLoaded,
  } = props;
  const { t } = useTranslation();

  const navigateToEfc = useNavigateToEfc();
  const activeSearch = useAppSelector(selectActiveSearch);
  const handleEFCCardClick = (efc: EFC) => {
    navigateToEfc(efc.Id.toString());
  };

  //EFCS

  const {
    results: data,
    onContentVisible,
    isLoading,
  } = useSearchGetResults({
    onResultLoaded: handleEFCResultsLoaded,

    uniqueIdentifier: 'EfcCodeStr',
    fetcher,
    countFetcher,
    from: 'efc codes',
    shouldSearchLogicRun: true,
  });
  if (!renderEfcResults) return null;

  if (isLoading) {
    return (
      <div className="py-4 text-center text-lg font-medium text-concrete">
        {t('Loading')}...
      </div>
    );
  }

  if (data.length === 0) {
    return <div className="px-4">{t('No results')}</div>;
  }

  if (
    data.length === 1 &&
    totalResultsCount === 1 &&
    localStorage.getItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED) === 'true'
  ) {
    if (
      (activeSearch?.scope === 'everywhere' && areAllResultsLoaded) ||
      activeSearch?.scope !== 'everywhere'
    ) {
      localStorage.setItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED, 'false');
      handleEFCCardClick(data[0]);
    }
  }

  return (
    <>
      {renderEfcResults ? (
        <>
          {data?.map((result, index) => {
            return (
              <LazyLoader
                onContentVisible={onContentVisible}
                lastItemIndex={data.length - 1 === index}
                key={result.EfcCodeStr}
              >
                <EFCCard
                  number={index + 1}
                  efc={result}
                  onClick={() => handleEFCCardClick(result)}
                />
              </LazyLoader>
            );
          })}
        </>
      ) : null}
    </>
  );
}

export default EFCResults;
