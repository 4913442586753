import React from 'react';
import { useTranslation } from 'react-i18next';
import { wrap } from 'comlink';

import ActionableListItem from '../../../components/ActionableListItem';
import useNavigateToProduct from '../../../hooks/useNavigateToProduct';

import LazyLoader from '../../../components/utility/LazyLoader';

import useSearchGetResults from '../../../hooks/useSearchGetResults';

import {
  SHOULD_SINGLE_SEARCH_BE_PERFORMED,
  lazyLoadLimit,
} from '../../../constants';
import { ProductSearchWorkerType } from '../../../worker/productSearch/searchWorker';
import { ProductSearchCountWorkerType } from '../../../worker/productSearch/searchCountWorker';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import useCheckUserIsPartner from '../../../hooks/useCheckUserIsPartner';

interface Props {
  handleProductResultsLoaded: (count: number) => void;
  renderProductResults: boolean;
  totalResultsCount: number;
  areAllResultsLoaded: boolean;
}

const fetcher = async (
  regex: RegExp,
  offset: number,

  selectedFilter?: string
) => {
  const worker = new Worker(
    new URL('../../../worker/productSearch/searchWorker.ts', import.meta.url),
    { name: 'productWorker' }
  );

  const workerApi = wrap<ProductSearchWorkerType>(worker);

  try {
    const results = await workerApi.search({
      moduleFilter: selectedFilter,
      totalRes: false,
      offset: offset,
      regex,
      limit: lazyLoadLimit,
    });

    worker.terminate();
    return results;
  } catch (e) {
    console.log(e);
    worker.terminate();
    return [];
  }
};
const countFetcher = async (regex: RegExp, selectedFilter?: string) => {
  const worker = new Worker(
    new URL(
      '../../../worker/productSearch/searchCountWorker.ts',
      import.meta.url
    ),
    { name: 'productCountWorker' }
  );
  const workerApi = wrap<ProductSearchCountWorkerType>(worker);

  try {
    const resultCount = await workerApi.searchCount({
      moduleFilter: selectedFilter,
      totalRes: true,
      offset: null,
      regex,
      limit: undefined,
    });
    worker.terminate();
    if (typeof resultCount === 'number') {
      return resultCount;
    }
    return 0;
  } catch (e) {
    worker.terminate();
    return 0;
  }
};

function ProductResults(props: Props) {
  const {
    handleProductResultsLoaded,
    renderProductResults,
    totalResultsCount,
    areAllResultsLoaded,
  } = props;
  const { t } = useTranslation();

  const navigateToProduct = useNavigateToProduct();
  const activeSearch = useAppSelector(selectActiveSearch);
  const isPartner = useCheckUserIsPartner();
  //PRODUCTS
  const {
    results: data,
    onContentVisible,
    isLoading,
  } = useSearchGetResults({
    onResultLoaded: handleProductResultsLoaded,

    uniqueIdentifier: 'ProductId',
    fetcher,
    countFetcher,
    from: 'products',
    shouldSearchLogicRun: !isPartner,
  });
  if (!renderProductResults) return null;
  if (isLoading) {
    return (
      <div className="py-4 text-center text-lg font-medium text-concrete">
        {t('Loading')}...
      </div>
    );
  }

  if (data.length === 0) {
    return <div className="px-4">{t('No results')}</div>;
  }

  if (
    data.length === 1 &&
    totalResultsCount === 1 &&
    localStorage.getItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED) === 'true'
  ) {
    if (
      (activeSearch?.scope === 'everywhere' && areAllResultsLoaded) ||
      activeSearch?.scope !== 'everywhere'
    ) {
      localStorage.setItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED, 'false');
      navigateToProduct(data[0].ProductId);
    }
  }

  return (
    <>
      {renderProductResults ? (
        <>
          {data.map((result, index) => (
            <LazyLoader
              onContentVisible={onContentVisible}
              lastItemIndex={data.length - 1 === index}
              key={result.ProductId}
            >
              <ActionableListItem
                primaryAction="navigate"
                title={result.ProductId}
                subText={result.Description}
                onClick={() => navigateToProduct(result.ProductId)}
              ></ActionableListItem>
            </LazyLoader>
          ))}
        </>
      ) : null}
    </>
  );
}

export default ProductResults;
