import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ActionableListItem from '../../components/ActionableListItem';
import CopyAllOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Collapsible from '../../components/collapsible/Collapsible';
import { useToast } from '../../components/toast/ToastProvider';
import useNavigateToPart from '../../hooks/useNavigateToPart';
import { ChildLevel } from '../../Models/Enumerations';
import { IPartsPageData } from './PartPage';
import useClipBoardCopy from '../../hooks/useClipBoardCopy';
import { searchActions } from '../../features/search/searchSlice';
import { useAppDispatch } from '../../app/hooks';

interface Props {
  partsPageData: IPartsPageData;
  title: string;
  isCollapsed: boolean;
}

function CategoryWithTree(props: Props) {
  const { t } = useTranslation();
  const { pushToast } = useToast();
  const navigateToPart = useNavigateToPart();
  const navigate = useNavigate();
  const clipboardCopy = useClipBoardCopy();
  const dispatch = useAppDispatch();
  const handleClick = (idGen: string, selectedPartId: string) => {
    navigateToPart(idGen, selectedPartId);
  };

  let onClear;
  const onIconClick =
    (searchTerm: string) =>
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      dispatch(searchActions.changeSearchTerm(searchTerm));
      dispatch(searchActions.setScope('repairs'));
      navigate('/search/results', {
        state: { selectedTab: 'repairs', searchTerm },
      });
    };

  return (
    <Collapsible headingTitle={`${props.title}`} onClear={onClear}>
      {props?.partsPageData?.partParentData?.length ? (
        <Collapsible
          headingTitle={t(`View Parent Ids`)}
          startCollapsed={props.isCollapsed}
        >
          {props.partsPageData.partParentData.map((item) => (
            <ActionableListItem
              key={item.materialId}
              icon={CopyAllOutlinedIcon}
              title={item.materialId}
              subText={item.partName}
              primaryAction="navigate"
              enhancedCopying
              onClick={() => {
                handleClick(
                  item.materialId,
                  props.partsPageData.partsData.MaterialId
                );
              }}
              {...(item.hasRepairActions
                ? {
                    intermediateIcon: BuildCircleOutlinedIcon,
                    onClickIntermediateIcon: onIconClick(item.materialId),
                  }
                : {})}
              onClickIcon={() => {
                clipboardCopy(item.materialId);
                pushToast({
                  message: t(`Part Number {{partId}} copied to the clipboard`, {
                    partId: item.materialId,
                  }),
                });
              }}
            />
          ))}
        </Collapsible>
      ) : (
        <></>
      )}
      {props.partsPageData.partsData && (
        <ActionableListItem
          key={props.partsPageData.partsData.MaterialId}
          icon={CopyAllOutlinedIcon}
          title={props.partsPageData.partsData.MaterialId}
          subTitle={
            props.partsPageData.partsData.ManufactureNumber &&
            `(${t('mfg')}: ${props.partsPageData.partsData.ManufactureNumber})`
          }
          subText={props.partsPageData.partsData.PartName}
          primaryAction="copy"
          enhancedCopying
          hidePrimaryActionIcon={true}
          {...(props.partsPageData.partsData.HasRepairActions
            ? {
                intermediateIcon: BuildCircleOutlinedIcon,
                onClickIntermediateIcon: onIconClick(
                  props.partsPageData.partsData.MaterialId
                ),
              }
            : {})}
          onClick={() => {
            clipboardCopy(props.partsPageData.partsData.MaterialId);
            pushToast({
              message: t(`Part Number {{partId}} copied to the clipboard`, {
                partId: props.partsPageData.partsData.MaterialId,
              }),
            });
          }}
          onClickIcon={() => {
            clipboardCopy(props.partsPageData.partsData.MaterialId);
            pushToast({
              message: t(`Part Number {{partId}} copied to the clipboard`, {
                partId: props.partsPageData.partsData.MaterialId,
              }),
            });
          }}
          childLevel={ChildLevel.FirstGen}
          appearance={'colorInverted'}
        />
      )}
      {props?.partsPageData?.partChildData?.length ? (
        <Collapsible headingTitle={t(`View Child Ids`)} startCollapsed={true}>
          {props.partsPageData.partChildData.map((item) => (
            <ActionableListItem
              key={item.materialId}
              icon={CopyAllOutlinedIcon}
              title={item.materialId}
              subText={item.partName}
              primaryAction="navigate"
              enhancedCopying
              {...(item.hasRepairActions
                ? {
                    intermediateIcon: BuildCircleOutlinedIcon,
                    onClickIntermediateIcon: onIconClick(item.materialId),
                  }
                : {})}
              onClick={() => {
                handleClick(
                  item.materialId,
                  props.partsPageData.partsData.MaterialId
                );
              }}
              onClickIcon={() => {
                clipboardCopy(item.materialId);
                pushToast({
                  message: t(`Part Number {{partId}} copied to the clipboard`, {
                    partId: item.materialId,
                  }),
                });
              }}
              childLevel={ChildLevel.SecondGen}
            />
          ))}
        </Collapsible>
      ) : (
        <></>
      )}
    </Collapsible>
  );
}

export default CategoryWithTree;
