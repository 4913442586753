import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import LayoutForSearch from './components/layout/LayoutForSearch';
import LayoutWithoutHeader from './components/layout/LayoutWithoutHeader';
import PageNotFound from './PageNotFound';
import EFC from './pages/EFC/EFC';
import EFCByCode from './pages/EFC/EFCByCode';
import EFCCodes from './pages/EFCCodes/EFCCodes';
import EquipmentPage from './pages/Equipment/EquipmentPage';
import ExternalSource from './pages/ExternalSource/ExternalSource';
import Home from './pages/Home/Home';
import Info from './pages/Info/Info';
import MePage from './pages/Me/MePage';
import Menu from './pages/Menu/Menu';
import PartPage from './pages/Parts/PartPage';
import PartsPage from './pages/Parts/PartsPage';
import ProductPage from './pages/Products/ProductPage';
import ProductsPage from './pages/Products/ProductsPage';
import RecentlyViewed from './pages/RecentlyViewed/RecentlyViewed';
import RepairAction from './pages/RepairAction/RepairAction';
import RepairFromExternal from './pages/RepairAction/RepairFromExternal';
import SavedItems from './pages/SavedItems/SavedItems';
import Filters from './pages/Search/Filters/Filters';
import Query from './pages/Search/Query/Query';
import ModuleResults from './pages/Search/Results/ModuleResults';
import Results from './pages/Search/Results/Results';
import Search from './pages/Search/Search';
import Language from './pages/Settings/Language/Language';
import Settings from './pages/Settings/Settings';
import HelpFeedback from './pages/Help/Help&Feedback';
import Disclaimer from './pages/Help/Disclaimer/Disclaimer';
import TestPage from './pages/Test/TestPage';
import RepairResult from './pages/RepairAction/RepairResult';
import NotificationsList from './pages/Notification/NotificationsList';
import NotificationPage from './pages/Notification/NotificationPage';

function AppRoutes() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="search">
          <Route index element={<Search />} />
          <Route path="filters" element={<Filters />} />
          <Route path="results" element={<Results />}>
            <Route index element={<Results />} />
            <Route path=":activeTab" element={<Results />} />
          </Route>
        </Route>
        <Route path="moduleResults" element={<ModuleResults />} />
        <Route path="saved" element={<SavedItems />} />
        <Route path="me" element={<MePage />} />
        <Route path="recent" element={<RecentlyViewed />} />
        <Route path="efccodes" element={<EFCCodes />} />
        <Route
          path="repair/:idGeneric/:efcId/:externalSource"
          element={<RepairAction />}
        />

        <Route path="repairs" element={<RepairResult />} />
        <Route path="raid/:id" element={<RepairFromExternal />} />
        <Route path="equipment/:endpointId/:tab" element={<EquipmentPage />} />
        <Route
          path="equipment/:endpointId/:tab/:serviceTaskId"
          element={<EquipmentPage />}
        />
        <Route
          path="equipment"
          element={<EquipmentPage displayLastSystem={true}></EquipmentPage>}
        />
        <Route path="info" element={<Info />} />
        <Route path="settings">
          <Route index element={<Settings />} />
          <Route path="language" element={<Language />} />
        </Route>
        <Route path="helpFeedback">
          <Route index element={<HelpFeedback />} />
          <Route path="disclaimer" element={<Disclaimer />} />
        </Route>
        <Route path="*" element={<PageNotFound />}></Route>
        <Route path="efc/:idGeneric" element={<EFC />} />
        <Route path="efccode/:code" element={<EFCByCode />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="products/:idGeneric" element={<ProductPage />} />
        <Route path="parts" element={<PartsPage />} />
        <Route path="parts/:idGeneric" element={<PartPage />} />
        <Route path="parts/:idGeneric/:selectedPartId" element={<PartPage />} />
        <Route path="test" element={<TestPage />} />
        <Route path="externalSource" element={<ExternalSource />} />
        <Route path="menu" element={<Menu />} />
        <Route path="notifications">
          <Route index element={<NotificationsList />} />
          <Route path=":id" element={<NotificationPage />} />
        </Route>
        <Route />
      </Route>

      <Route element={<LayoutForSearch />}>
        <Route path="search/query" element={<Query />} />
        <Route path="search/query/:everywhere" element={<Query />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
